import React from 'react';
// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Worker Management',
    path: '/dashboard/worker',
    icon: getIcon('eva:file-text-fill'),
    role: { read: true },
    module: 'worker',
    name: ['FIC_SUPERADMIN', 'FIC_ADMIN'],
  },
  {
    title: 'User Management',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
    role: { read: true },
    module: 'user',
    name: ['FIC_SUPERADMIN'],
  },
  {
    title: 'Company Management',
    path: '/dashboard/company',
    icon: getIcon('mdi:company'),
    role: { read: true },
    module: 'company',
    name: ['FIC_SUPERADMIN'],
  },
  {
    title: 'Audit Logs',
    path: '/dashboard/audit',
    icon: getIcon('eva:book-open-fill'),
    role: { read: true },
    module: 'audit',
    name: ['FIC_SUPERADMIN'],
  },
];

export default navConfig;
