/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import Cookies from 'js-cookie';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Box, Divider, Typography, MenuItem, Avatar, IconButton,
} from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
// mocks_
import account from '../../_mock/account';
import { auth } from '../../firebase';

// ----------------------------------------------------------------------

// const MENU_OPTIONS = [
//   {
//     label: 'Home',
//     icon: 'eva:home-fill',
//     linkTo: '/',
//   },
//   {
//     label: 'Profile',
//     icon: 'eva:person-fill',
//     linkTo: '#',
//   },
//   {
//     label: 'Settings',
//     icon: 'eva:settings-2-fill',
//     linkTo: '#',
//   },
// ];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const MainName = Cookies.get('firstName') || Cookies.get('lastName') ? `${Cookies.get('firstName')} ${Cookies.get('lastName')}` : 'Annonymous';
  const EmailAdd = Cookies.get('email') ? Cookies.get('email') : 'annonymous@asiamobiliti.com';

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    signOut(auth).then(() => {
      setOpen(null);
      Cookies.remove('firstName');
      Cookies.remove('lastName');
      Cookies.remove('email');
      Cookies.remove('token');
      Cookies.remove('role');
      Cookies.remove('id');
      Cookies.remove('privilege');
      navigate('/login', { replace: true });
    }).catch((error) => {
      console.log(`Logout Error!: ${error}`);
    });
  };

  const closePopup = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={closePopup}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
        styleWidth={200}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {MainName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {EmailAdd}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        <MenuItem onClick={() => handleClose()} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
