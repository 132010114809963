/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React, {
  useMemo, useRef, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import {
  Stack,
  Button,
  Modal,
  Box,
  CircularProgress,
} from '@mui/material';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import { useReactToPrint } from 'react-to-print';
// import { getImageFirebase } from 'src/utils/getImageFirebase';
// import { APICreateWorkerCard } from 'src/utils/apiCalled';
import { formatDate } from '../../../utils/config';
import { APIUpdateWorker, APIUpdateWorkerCard } from '../../../utils/apiCalled';
import CardDesign from './CardDesign';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '16px',
  p: 2,
  display: 'flex',
  flexDirection: 'column',
};

export const loadingStyle = {
  position: 'absolute',
  top: '40%',
  left: '45%',
  transform: 'translate(-50%, -55%)',
  zIndex: 1,
};

export default function PrintJob({
  cardPrint, setCardPrint, handleDeleteClose, editValue, setBorderDisplay, borderDisplay, workerPrivi, cardIdDisplay, cardExpiryDate, setCardDisplay, setCardExpiryModel,
}) {
  // const getImageWorker = async (location) => {
  //   let imgURL = '';
  //   await getImageFirebase(location).then((imgs) => {
  //     imgURL = imgs;
  //     setLoading(false);
  //   }).catch((error) => {
  //     setLoading(false);
  //     setOpen(true);
  //     setVariant('error');
  //     setErrMsg(`Error!, ${error}`);
  //   });
  //   return imgURL;
  // };

  const token = Cookies.get('token');
  const workerId = editValue ? editValue._id : null;
  // const statusPrinted = editValu
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = useRef(null);

  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('old boring text');

  const handleAfterPrint = useCallback(() => {
    // console.log('`onAfterPrint` called'); // tslint:disable-line no-console
    setCardPrint(false); // to display back the border
    setBorderDisplay(true);
    setCardExpiryModel(false);
    setLoading(false);
  }, []);

  const handleBeforePrint = useCallback(() => {
    // console.log('`onBeforePrint` called'); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = useCallback(() => {
    // console.log('`onBeforeGetContent` called'); // tslint:disable-line no-console
    setLoading(true);
    setBorderDisplay(false); // to remove the border
    setText('Loading new text...');

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText('New, Updated Text!');
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = useCallback(() => componentRef.current, [componentRef.current]);
  //   console.log('reactToPrint', reactToPrintContent);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'FWID',
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  const printButton = async () => {
    try {
      const params = {
        status: 'printed',
      };
      const result = await APIUpdateWorkerCard(params, workerId, setLoading, token);
      // console.log('result', result);
      if (result.data.status === 200) {
        const { data } = result.data;
        setLoading(false);
        setCardDisplay({
          cardId: data.card.cardId,
          status: data.card.status,
          validFrom: data.card.validFrom,
          validTo: data.card.validTo,
        });
        handlePrint();
      } else {
        setLoading(false);
        // console.log('Error');
      }
    } catch (err) {
      setLoading(false);
      // console.log(err);
    }
    // this function to call the api
  };

  React.useEffect(() => {
    if (
      text === 'New, Updated Text!'
      && typeof onBeforeGetContentResolve.current === 'function'
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);
  //   console.log(data);
  return (
    <div>
      <Modal
        open={cardPrint}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          { loading && <CircularProgress color="success" sx={loadingStyle} />}
          <CardDesign reference={componentRef} text={text} value={editValue} borderDisplay={borderDisplay} cardIdDisplay={cardIdDisplay} cardExpiryDate={cardExpiryDate} />
          <Stack
            spacing={2}
            direction="row"
            sx={{
              marginTop: '16px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button variant="outlined" onClick={() => printButton()} disabled={workerPrivi.length > 0 && !workerPrivi[0].role.update} startIcon={<PrintRoundedIcon />}>Print Card</Button>
          </Stack>
        </Box>
      </Modal>

    </div>
  );
}

PrintJob.propTypes = {
  cardPrint: PropTypes.func,
  handleDeleteClose: PropTypes.func,
  editValue: PropTypes.array,
  // sortingCardData: PropTypes.func,
  setBorderDisplay: PropTypes.func,
  borderDisplay: PropTypes.bool,
  workerPrivi: PropTypes.bool,
  cardIdDisplay: PropTypes.string,
  setCardPrint: PropTypes.func,
  setCardDisplay: PropTypes.func,
  // cardDisplay: PropTypes.object,
  cardExpiryDate: PropTypes.string,
  setCardExpiryModel: PropTypes.func,
};
