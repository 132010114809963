import React, { useEffect, useMemo } from 'react';
import { Navigate, useRoutes, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import DashboardApp from './pages/DashboardApp';
import Worker from './pages/Worker';
import Company from './pages/Company';
import NoAccess from './pages/NoAccess';
import AuditLogs from './pages/AuditLogs';

// ----------------------------------------------------------------------

export default function Router() {
  const navigate = useNavigate();
  const token = Cookies.get('token');
  const roleName = Cookies.get('role');
  const privilege = Cookies.get('privilege');
  // const roleName = useMemo(() => {
  //   let name = '';
  //   if (role) {
  //     const nameObj = role;
  //     console.log(nameObj);
  //     name = nameObj;
  //   }
  //   return name;
  // });

  const adminPrivi = useMemo(() => {
    let userP = [];
    if (roleName === 'FIC_ADMIN') {
      const userTypeObj = privilege || {};
      userP = userTypeObj;
    } else {
      userP = [];
    }
    return userP;
  }, [roleName]);

  // console.log(adminPrivi);

  // this function is to check if the user authenticate or not.
  // if authenticate (have value in cookies) it will navigate to
  // dashboard else redirect to login page.
  const settingPage = () => {
    if (token) {
      navigate('/dashboard/worker', { replace: true });
    } else {
      navigate('/login', { replace: true });
    }
  };

  // to call settingPage
  useEffect(() => {
    settingPage();
  }, []);

  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        (roleName === 'FIC_SUPERADMIN' || 'FIC_ADMIN') ? { path: 'worker', element: <Worker /> } : { path: 'worker', element: <NoAccess /> },
        roleName === 'FIC_SUPERADMIN' ? { path: 'user', element: <User /> } : { path: 'user', element: <NoAccess /> },
        roleName === 'FIC_SUPERADMIN' ? { path: 'company', element: <Company /> } : { path: 'company', element: <NoAccess /> },
        roleName === 'FIC_SUPERADMIN' ? { path: 'audit', element: <AuditLogs /> } : { path: 'audit', element: <NoAccess /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/worker" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
