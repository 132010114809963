import React, { forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const Alert = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

const MuiSnackbar = styled(Snackbar)(({ theme }) => ({
  '& .SnackbarItem-variantSuccess': {
    backgroundColor: theme.palette.success.dark, // your custom color here
  },
  '& .SnackbarItem-variantError': {
    backgroundColor: theme.palette.error.dark, // your custom color here
  },
}));
export default function SnackBar({
  alert, open, handleClose, errorMsg,
}) {
  // this to display the snackbar color based on alert status
  const severityAlert = (value) => {
    switch (value) {
      case 'success':
        return 'success';
      case 'error':
        return 'error';
      default:
        return 'success';
    }
  };

  return (
    <MuiSnackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={severityAlert(alert)} sx={{ width: '100%' }}>
        <div>{errorMsg}</div>
      </Alert>
    </MuiSnackbar>
  );
}

SnackBar.propTypes = {
  alert: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  errorMsg: PropTypes.string.isRequired,
};
