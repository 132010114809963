/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React, { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Modal,
  TextField,
  FormControl,
  Typography,
  Button,
  Stack,
  Card,
  Icon,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
// import { useReactToPrint } from 'react-to-print';
// import BulkPrintJob from './BulkPrintJob';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  width: '100vw',
  height: '100vh',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  // pt: 2,
  // px: 4,
};

const CardExpiryModal = ({
  setViewOpen,
  viewOpen,
  widths,
  heights,
  cardExpiryDate,
  setCardExpiryDate,
  createCardAPI,
  editValue,
  source,
  borderDisplay,
  setBorderDisplay,
  bulkExpDateFunction,
}) => {
  const handleClose = () => {
    setViewOpen(false);
  };

  return (
    <Modal
      open={viewOpen}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box style={{ ...style }}>
        <Card sx={{
          width: widths, height: heights, padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center',
        }}
        >
          <Icon
            sx={{
              color: '#939598', fontWeight: 700, cursor: 'pointer', position: 'absolute', top: 16, right: 16,
            }}
            onClick={handleClose}
          >
            <CloseRoundedIcon />
          </Icon>
          <Typography
            sx={{
              display: 'flex', width: '100%', justifyContent: 'center', marginBottom: '8px',
            }}
            variant="subtitle1"
          >
            Card Expiry Date
          </Typography>
          <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                  // disabled={editState}
                label="Valid Card To"
                inputFormat="dd/MM/yyyy"
                disablePast
                value={cardExpiryDate}
                onChange={(newValue) => {
                  setCardExpiryDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </FormControl>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              marginTop: '16px',
            }}
          >
            <Button variant="outlined" disabled={!cardExpiryDate} onClick={() => { bulkExpDateFunction(); handleClose(); }}>Confirm</Button>
          </Stack>
        </Card>
      </Box>
    </Modal>
  );
};

CardExpiryModal.propTypes = {
  setViewOpen: PropTypes.func,
  viewOpen: PropTypes.bool,
  widths: PropTypes.string,
  heights: PropTypes.string,
  cardExpiryDate: PropTypes.string,
  setCardExpiryDate: PropTypes.func,
  createCardAPI: PropTypes.func,
  editValue: PropTypes.bool,
  source: PropTypes.string,
  borderDisplay: PropTypes.bool,
  setBorderDisplay: PropTypes.func,
  bulkExpDateFunction: PropTypes.func,
};

export default CardExpiryModal;
