/* eslint-disable no-shadow */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable max-len */
import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import Cookies from 'js-cookie';
import {
  Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton,
} from '@mui/material';
//
import Iconify from './Iconify';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
  privileges: PropTypes.array,
  priviName: PropTypes.string,
};

function NavItem({
  item, active, privileges, priviName,
}) {
  const theme = useTheme();

  // console.log(privileges);
  const isActiveRoot = active(item.path);

  const {
    title, path, icon, info, children, module,
  } = item;

  // this for the role authentication for sidebar displayed
  const roleAuthentication = useMemo(() => {
    let priv = true;
    if (priviName === 'FIC_SUPERADMIN') {
      priv = true;
    } else if (priviName === 'FIC_ADMIN') {
      const privi = [{ module: 'worker', read: true }];
      priv = privi.length > 0;
    } else {
      priv = false;
    }
    return priv;
  }, []);

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  };

  // const disableNav = privileges && pri

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Iconify
            icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main',
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <div>
      {
        roleAuthentication && (
          <ListItemStyle
            component={RouterLink}
            to={path}
            sx={{
              ...(isActiveRoot && activeRootStyle),
            }}
          >
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
            <ListItemText disableTypography primary={title} />
            {info && info}
          </ListItemStyle>
        )
      }
    </div>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const role = Cookies.get('role');
  const privilege = Cookies.get('privilege');

  const privileges = useMemo(() => {
    const privi = privilege ? JSON.parse(privilege) : [];
    return privi;
  }, [role]);

  const priviName = useMemo(() => {
    const convert = role || null;
    const name = convert || null;
    return name;
  }, [role]);

  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item) => (
          item.name.includes(role) ? (
            <NavItem key={item.title} item={item} active={match} privileges={privileges} priviName={priviName} />
          ) : (
            <>
            </>
          )
        ))}
      </List>
    </Box>
  );
}
