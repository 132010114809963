/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, createTheme,
} from '@mui/material';
import { formatDate } from '../../../utils/config';
import { countryListAllIsoData } from '../../../listOfCountry';
import ThemeProvider from 'src/theme';
import { el } from 'date-fns/locale';

const styleCard = {
  position: 'relative',
  width: '8.9cm',
  height: '5.1cm',
  backgroundColor: '#FFF',
  // float: 'left',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gridTemplateRows: '1fr',
  gridAutoColumns: 'auto',
};

const withBorder = {
  border: '0.5px solid #000000',
  paddingBottom: '20px',
};

const titlefontSize = {
  fontSize: '4.5pt',
  fontWeight: 'normal',
  fontFamily: 'Open Sans',
};

const subTitleFontSize = {
  fontSize: '5.5pt',
  fontWeight: 'bold',
  fontFamily: 'Noto Sans Mono',
};

const gridColumnText = {
  display: 'grid',
  // gridAutoColumns: '1fr',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr',
  gap: '0px 11px',
  width: '146px',
  wordWrap: 'break-word',
  // marginBottom: '8px',
};

const gridColumnDetails = {
  display: 'grid',
  gridTemplateColumns: 'fit-content(100%) fit-content(100%) fit-content(100%)',
  gridTemplateRows: '1fr',
  gap: '0px 21px',
  marginBottom: '9px',
};

const gridDetailsRows = {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr 1fr',
  width: 'fit-content',
  gap: '7px',
};

const gridColumnSecondRow = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateRows: '1fr',
  gap: '0px 11px',
};

const gridColumnNameRow = {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr',
};

const gridColumnFirstRow = {
  display: 'grid',
  // gridAutoColumns: '1fr',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateRows: '1fr',
  gap: '0px 11px',
};

const fontSizeCardId = {
  display: 'grid',
  fontSize: '5.5pt',
  fontWeight: 'bold',
  fontFamily: 'Noto Sans Mono',
  marginTop: '20px',
  // marginLeft: '2px',
};

const nameCard = {
  fontWeight: 'bold',
  fontFamily: 'Noto Sans Mono',
  fontSize: '7pt',
  // height: '28.2px',
};

const detailContainer = {
  display: 'grid',
  gridAutoColumns: '1fr',
  gridTemplateColumns: 'fit-content(100%)',
  gridTemplateRows: '24px fit-content(100%) fit-content(100%)',
  float: 'right',
  marginLeft: '4px',
  marginTop: '60px',
};

const gridDetailsRowContainer ={ 
  display: 'grid',
  // gap: '3px',
};

const theme = createTheme({
  components: {
    MuiAvatar: {
      styleOverrides: {
        '.MuiAvatar-img': {
          width: 'auto',
        }
      }
    }
  }
});

export default function CardDesign({
  reference, value, borderDisplay, cardIdDisplay, cardExpiryDate,
}) {
  const country = useMemo(() => {
    const filterCountry = value.nationality ? countryListAllIsoData.filter((c) => c.code3 === value.nationality) : '-';
    return filterCountry[0].name;
  }, []);

  const uppercaseValue = (cardValue) => {
    const valueUppercase = cardValue !== undefined ? cardValue.toUpperCase() : null;
    return valueUppercase;
  };

  // console.log('exp Date', value)

  const swithValueFirstName = () => {
    let stringValue = '';

    if (value.firstName && value.middleName && value.lastName) {
      stringValue = `${uppercaseValue(value.firstName)} ${uppercaseValue(value.middleName)} ${uppercaseValue(value.lastName)}`;
    } else if (value.firstName) {
      if (value.middleName && value.lastName) {
        stringValue = `${uppercaseValue(value.firstName)} ${uppercaseValue(value.middleName)} ${uppercaseValue(value.lastName)}`;
      } else if (value.middleName) {
        stringValue = `${uppercaseValue(value.firstName)} ${uppercaseValue(value.middleName)}`;
      } else if (value.lastName) {
        stringValue = `${uppercaseValue(value.firstName)} ${uppercaseValue(value.lastName)}`;
      } else {
        // console.log('go here');
        stringValue = `${uppercaseValue(value.firstName)}`;
      }
    } else if (value.middleName) {
      if (value.lastName) {
        stringValue = `${uppercaseValue(value.middleName)} ${uppercaseValue(value.lastName)}`
      } else if (value.middleName) {
        stringValue = `${uppercaseValue(value.middleName)}`
      } else {
        stringValue = '-'
      }
    } else if (value.lastName){
      stringValue = `${uppercaseValue(value.lastName)}`
    } else {
      stringValue = '-'
    }

    return stringValue;
  };

  return (
    <div style={withBorder}>
      <div style={styleCard} className="font-text" ref={reference}>
          <div style={{
            // marginTop: '30px',
            height: '113px',
            float: 'left',
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr 1fr',
            marginTop: '86.4px',
            marginLeft: '14px',
            // position: 'absolute',
          }}
          >
              <Avatar
                srcSet={value.photoURL}
                variant="square"
                sizes="small"
                sx={{
                  height: '90px',
                  width: '90px',
                  // borderRadius: '8px',
                  marginLeft: '4px',
                  position: '',
                  display: 'grid',
                  '& .MuiAvatar-img': {
                    objectFit: 'contain'
                  }
                }}
              />
            <div style={fontSizeCardId}>
              {/* {cardIdDisplay.toUpperCase()} */}
              {value.card.cardId.toUpperCase()}
            </div>
        </div>
        <div style={detailContainer}>
            <div style={gridColumnNameRow}>
              <div style={nameCard}>
                {swithValueFirstName()}
              </div>
            </div>
            <div style={gridColumnDetails}>
              <div style={gridDetailsRows}>
                <div style={gridDetailsRowContainer}>
                  <div style={titlefontSize}>
                    Nationality
                  </div>
                  <div style={subTitleFontSize}>
                    {country ? country.toUpperCase() : '-'}
                  </div>
                </div>
                <div style={gridDetailsRowContainer}>
                  <div style={titlefontSize}>
                    Passport Number
                  </div>
                  <div style={subTitleFontSize}>
                    {value.passportNumber ? value.passportNumber : '-'}
                  </div>
                </div>
              </div>
              <div style={gridDetailsRows}>
                <div style={gridDetailsRowContainer}>
                  <div style={titlefontSize}>Expiry Date</div>
                  <div style={subTitleFontSize}>{value.card.validTo ? formatDate(value.card.validTo) : '-'}</div>
                </div>
                <div style={gridDetailsRowContainer}>
                  <div style={titlefontSize}>
                    Date of Birth
                  </div>
                  <div style={subTitleFontSize}>
                    {value.birthDate ? formatDate(value.birthDate) : '-'}
                  </div>
                </div>
              </div>
              <div style={gridDetailsRows}>
                <div></div>
                <div style={gridDetailsRowContainer}>
                  <div style={titlefontSize}>Gender</div>
                  <div style={subTitleFontSize}>{value.gender ? value.gender.substring(0, 1).toUpperCase() : '-'}</div>
                </div>
              </div>
            </div>
          {/* <div style={gridColumnFirstRow}>
            <div>
              <div style={titlefontSize}>
                Nationality
              </div>
              <div style={subTitleFontSize}>
                {country ? country.toUpperCase() : '-'}
              </div>
            </div>
            <div>
              <div style={titlefontSize}>Expiry Date</div>
              <div style={subTitleFontSize}>{value.card.validTo ? formatDate(value.card.validTo) : '-'}</div>
            </div>
          </div> */}
          {/* <div style={gridColumnSecondRow}>
            <div>
              <div style={titlefontSize}>
                Passport Number
              </div>
              <div style={subTitleFontSize}>
                {value.passportNumber ? value.passportNumber : '-'}
              </div>
            </div>
             <div>
              <div style={titlefontSize}>
                Date of Birth
              </div>
              <div style={subTitleFontSize}>
                {value.birthDate ? formatDate(value.birthDate) : '-'}
              </div>
            </div>
            <div>
              <div style={titlefontSize}>Gender</div>
              <div style={subTitleFontSize}>{value.gender ? value.gender.toUpperCase() : '-'}</div>
            </div>
          </div> */}
          <div style={gridColumnText}>
            <div style={gridDetailsRowContainer}>
              <div style={titlefontSize}>Place of Employment</div>
              <div style={subTitleFontSize}>{value.companyAddress ? value.companyAddress.toUpperCase() : '-'}</div>
            </div>
          </div>
        </div>
      </div>
      <style>
        {
        `
         .font-text {
          // -webkit-font-smoothing: none;
          // font-smooth: none;
          text-shadow:
            0 0 0px,
            0 0 0px,
            0 0 0px,
            0 0 0px,
            0 0 0px,
            0 0 0px,
            0 0 0px,
            0 0 0px,
            0 0 0px,
            0 0 0px,
            0 0 0px;
         }
        `
      }
      </style>
    </div>
  );
}

CardDesign.propTypes = {
  reference: PropTypes.string,
  value: PropTypes.array,
  borderDisplay: PropTypes.bool,
  cardIdDisplay: PropTypes.string,
  cardExpiryDate: PropTypes.string,
};
