import React from 'react';
import { useNavigate } from 'react-router-dom';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { signOut } from 'firebase/auth';
import { auth } from './firebase';
import Cookies from 'js-cookie';

import AuthVerify from './AuthVerify';
// ----------------------------------------------------------------------
// const navigate = useNavigate();

const logout = () => {
  signOut(auth).then(() => {
    Cookies.remove('firstName');
    Cookies.remove('lastName');
    Cookies.remove('email');
    Cookies.remove('token');
    Cookies.remove('role');
    Cookies.remove('id');
    Cookies.remove('privilege');
  window.location.assign(`${window.location.origin}/login`);  //   navigate('/login', { replace: true });
  }).catch((error) => {
    console.log(`Logout Error!: ${error}`);
  });
};

export default function App() {
  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
      <AuthVerify logOut={logout}/>
    </ThemeProvider>
  );
}
