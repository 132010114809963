/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
// import { sentenceCase } from 'change-case';
import React, {
  useState, useEffect, useMemo, useCallback, useRef,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Cookies from 'js-cookie';
import moment from 'moment';
// import { WithContext as ReactTags } from 'react-tag-input';
// material
import {
  Box,
  Card,
  Table,
  Stack,
  // Avatar,
  Paper,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  List,
  TableContainer,
  Icon,
  CircularProgress,
} from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// components
// import CardExpiryModal from 'src/components/dashboard/worker/CardExpiryModal';
import CardExpiryModal from '../components/dashboard/worker/CardExpiryModal';
import Page from '../components/Page';
// import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { TableListHead, TableListToolbar } from '../sections/@dashboard/user';
import {
  APIFetchWorker, APIFetchCompany, APIBulkUpdateWorker, APIBulkUpdateWorkerCard, APICardReadinessToPrint,
} from '../utils/apiCalled';

import SnackBar from '../components/SnackBar';
import MenuPopover from '../components/MenuPopover';
import TagsInput from '../components/TagsInput';
import ModalParent from '../components/ModalParent';
import FormWorker from '../components/dashboard/worker/FormWorker';
import { tableCircularLoadingStyle, rowsLimit } from '../utils/config';
import { getImageFirebase } from '../utils/getImageFirebase';
import ListPagination from '../components/ListPagination';
import BulkPrintJob from '../components/dashboard/worker/BulkPrintJob';

// mock
// import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'gender', label: 'Gender', alignRight: false },
  { id: 'passportNumber', label: 'Passport No', alignRight: false },
  { id: 'passportExpiry', label: 'Passport Expiry', alignRight: false },
  { id: 'company', label: 'Company', alignRight: false },
];

const styleDesktop = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75vw',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px',
  height: '600px',
  overflow: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  width: '100vw',
  height: '100vh',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  // pt: 2,
  // px: 4,
};

const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: '16px',
  maxHeight: '90%',
  width: '80vw',
  overflow: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default function User() {
  const token = Cookies.get('token');
  const role = Cookies.get('role');
  const privilege = Cookies.get('privilege');
  const onBeforeGetContentResolve = useRef(null);
  const componentRef1 = useRef();

  const [page, setPage] = useState(1); // pagination page
  const [searchTimeout, setSearchTimeout] = useState(null); // this for filterTimeout
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [callAPI, setcallAPI] = useState(false); // indicator to call api for pagination
  const [pageTotal, setPageTotal] = useState(null); // total pagination
  const [filterName, setFilterName] = useState('');
  const [openFilterPopUp, setOpenFilterPopUp] = useState(null); // filter popup
  const [openBulkUpdatePopUp, setOpenBulkUpdatePopUp] = useState(null); // bulk print popup
  const [rowsPerPage, setRowsPerPage] = useState(10); // pagination limit rows
  const [workerData, setWorkerData] = useState([]); // list of the workerData take from fetchWorker API
  const [loading, setLoading] = useState(false); // all the loading state (when called the API)
  const [open, setOpen] = useState(false); // open snackbar
  const [errMsg, setErrMsg] = useState(''); // error message snackbar
  const [variant, setVariant] = useState('');
  const [dataSet, setDataSet] = useState([]); // this state is to display updated card list;
  const [modalClick, setModalClick] = useState(false);
  const [editValue, setEditValue] = useState(null);
  const [editState, setEditState] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [apiCalledComponent, setApiCalledComponent] = useState(false); // this to call useEffect API if on different component.
  const [cardDisplay, setCardDisplay] = useState({
    cardId: null,
    status: null,
    validFrom: null,
    validTo: null,
  });
  const [getFilter, setGetFilter] = useState({
    name: null,
    status: null,
    phone: null,
    gender: null,
    nationality: null,
    company: null,
    email: null,
    passportNumber: null,
  });
  const [dupFilter, setDupFilter] = useState({
    name: [],
    status: [],
    phone: [],
    gender: [],
    nationality: [],
    company: [],
    email: [],
    passportNumber: [],
  });
  const [displayFailedWorker, setDisplayFailedWorker] = useState([]);
  const [cardExpiryDate, setCardExpiryDate] = useState(null);
  const [borderDisplay, setBorderDisplay] = useState(true);
  const [text, setText] = useState('');
  const [modalFailedWorker, setModalFailedWorker] = useState(false);

  const [checkedState, setCheckedState] = useState(false);
  const [indeterminateState, setIndeterminateState] = useState(false);

  const workerPrivi = useMemo(() => {
    let workerP = [];
    if (role === 'FIC_ADMIN') {
      workerP = JSON.parse(privilege);
    } else if (role === 'FIC_SUPERADMIN') {
      workerP = {
        create: true,
        read: true,
        update: true,
        delete: true,
        writeCard: true,
        printCard: true,
        verifyCard: true,
      };
    }
    return workerP;
  }, [role]);

  const disabledButton = useMemo(() => {
    let workerP = [];
    if (role === 'FIC_ADMIN') {
      const priv = JSON.parse(privilege);
      const checkAddButton = priv.create;
      workerP = checkAddButton;
    } else if (role === 'FIC_SUPERADMIN') {
      workerP = true;
    }
    return workerP;
  }, [role]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // this funcition to authenticate firebase
  const getImageWorker = async (location) => {
    let imgURL = '';
    await getImageFirebase(location).then((imgs) => {
      imgURL = imgs;
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      setOpen(true);
      setVariant('error');
      setErrMsg(`Error!, ${error}`);
    });
    return imgURL;
  };

  const companyName = (value) => {
    const filterCompany = companyList.filter((c) => c.id === value);
    if (filterCompany.length > 0) {
      return filterCompany[0].name;
    }
    return '-';

    return '';
  };

  // console.log(dupFilter);

  const handleAfterPrint = useCallback(() => {
    // console.log('`onAfterPrint` called'); // tslint:disable-line no-console
    setBorderDisplay(true);
    // setCardExpiryModel(false); // temporary will check later
    setSelected([]);
  }, []);

  const handleBeforePrint = useCallback(() => {
    // console.log('`onBeforePrint` called'); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = useCallback(() => {
    // console.log('`onBeforeGetContent` called'); // tslint:disable-line no-console
    setLoading(true);
    setBorderDisplay(false); // to remove the border
    setText('Loading new text...');

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText('New, Updated Text!');
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const handlePrint = useReactToPrint({
    content: () => componentRef1.current,
    documentTitle: 'FWID',
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  const apiCalled = async (params, passportNoFilter) => {
    try {
      const result = await APIFetchWorker(params, passportNoFilter, rowsPerPage, page, setLoading, null, token);
      // console.log('result', result);
      if (result.data && result.data.status === 200) {
        const { data, totalPage, user } = result.data;
        // console.log('data1', data);
        const reducer = data.length > 0 ? await Promise.all(data.map(async (x) => ({
          _id: x._id,
          firstName: x.firstName,
          middleName: x.middleName,
          lastName: x.lastName,
          email: x.email,
          company: x.companyOid,
          companyName: x.companyOid ? companyName(x.companyOid) : null,
          companyAddress: x.company ? x.company.address : '-',
          gender: x.gender,
          nationality: x.nationality,
          passportExpiry: x.passportExpiry,
          passportNumber: x.passportNumber,
          phone: x.phone,
          status: x.status,
          birthDate: x.birthDate,
          photoURL: x.photoURL ? await getImageWorker(x.photoURL) : '',
          card: x.card ? x.card : {
            cardId: null, status: null, validFrom: null, validTo: null,
          },
          isHotlisted: x.isHotlisted,
          hotlistReason: x.hotlistReason,
        }))) : [];

        Cookies.set('role', user.role);
        setPageTotal(totalPage);
        setLoading(false);
        setWorkerData(reducer);
      } else {
        setLoading(false);
        setOpen(true);
        setVariant('error');
        setErrMsg(`Error! ${result.response.data.message}`);
        // console.log('error from API');
      }
    } catch (err) {
      setLoading(false);
      setVariant('error');
      setOpen(true);
      setErrMsg(`Error! : ${err} Please try again`);
    }
  };

  const apiCompanyCalled = async (params) => {
    try {
      const result = await APIFetchCompany(params, null, 1000, page, setLoading, token);
      if (result.data && result.data.status === 200) {
        const { data } = result.data;
        const reducer = data.length > 0 ? data.map((x) => ({
          id: x._id && x._id,
          name: x.name && x.name,
          address: x.address && x.address,
          industry: x.industry && x.industry,
          representatives: x.representatives && x.representatives,
        })) : [];
        setCompanyList(reducer);
        setLoading(false);
      } else {
        setLoading(false);
        setOpen(true);
        setVariant('error');
        setErrMsg(`Error! ${result.response.data.message}`);
      }
    } catch (err) {
      setLoading(false);
      setOpen(true);
      setVariant('error');
      setErrMsg(`Error! ${err}`);
    }
  };

  const apiBulkPrintStatusChange = async () => {
    try {
      const params = {
        status: 'printed',
        workerId: selected.map((x) => x._id),
      };
      // console.log('params', params);
      const result = await APIBulkUpdateWorkerCard(params, setLoading, token);
      // console.log('checked on result bulk change status', result);
      // console.log('result cardstatus', result);
      if (result.data && result.data.status === 200) {
        // console.log('go here wargh');
        handlePrint();
        apiCalled();
        setLoading(false);
      } else {
        setLoading(false);
        // console.log('go hereeeeeeeeee');
        setOpen(true);
        setVariant('error');
        setErrMsg(`Error! ${result.response.data.message}`);
      }
    } catch (err) {
      // console.log('error', err);
      setLoading(false);
      setOpen(true);
      setVariant('error');
      setErrMsg(`Error! ${err}`);
    }
  };

  // this for first time come to the page
  useEffect(() => {
    if (token) {
      apiCompanyCalled();
      apiCalled(getFilter, filterName);
    }
    return () => {
      setWorkerData([]);
    };
  }, []);

  useEffect(() => {
    if (apiCalledComponent) {
      apiCalled(getFilter, filterName);
      setApiCalledComponent(false);
    }
  }, [apiCalledComponent]);

  // this to call when click limit or pagination
  useEffect(() => {
    if (callAPI) {
      apiCalled(getFilter, filterName);
      setcallAPI(false);
    }
  }, [callAPI]);

  const removeAllState = () => {
    setModalClick(false);
    apiCalled(getFilter, filterName);
    setEditValue(null);
    setEditState(false);
    setCardDisplay({
      cardId: null,
      status: null,
      validFrom: null,
      validTo: null,
    });
  };

  const handleOpen = () => {
    setModalClick(true);
  };

  // console.log(selected);

  const handleSelection = (e) => {
    setRowsPerPage(e.target.value);
    setPage(1);
    setcallAPI(true);
  };

  const handlePagination = (e, pageNo) => {
    setPage(pageNo);
    setcallAPI(true);
  };

  const closePopup = () => {
    setOpenFilterPopUp(null);
  };

  const filterButton = () => {
    apiCalled(getFilter, filterName);
  };


  const handleSelectClick = (event, name) => {
    const selectedIndex = selected.findIndex((x) => x._id === name._id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const filterIds = new Set(workerData.map((n) => n._id));
      const newSelected = selected.filter((n) => !filterIds.has(n._id));
      setSelected([...newSelected, ...workerData]);
    } else {
      const filterIds = new Set(workerData.map((n) => n._id));
      const filterList = selected.filter((item) => !filterIds.has(item._id));
      setSelected(filterList);
    }
  };

  const handleIndeterminateState = (currentPageData, selectedData) => {
    const allSelected = new Set(selectedData.map((n) => n._id));
    const unseletedData = currentPageData.filter((n) => !allSelected.has(n._id));

    let indeterminate = unseletedData.length > 0 && unseletedData.length < 10;

    setIndeterminateState(indeterminate);
  };

  const clearFilterButton = () => {
    // console.log('go here to clear the filter button');
    setGetFilter({
      name: null,
      status: null,
      phone: null,
      gender: null,
      nationality: null,
      company: null,
      email: null,
      passportNumber: null,
    });
    setDupFilter({
      name: [],
      status: [],
      phone: [],
      gender: [],
      nationality: [],
      company: [],
      email: [],
      passportNumber: [],
    });
    setApiCalledComponent(true);
  };

  // this is for filter
  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    // this to call api and setTimeout to 0.2 sec
    setSearchTimeout(
      setTimeout(() => {
        apiCalled(getFilter, filterName);
        setPage(1);
      }, 200),
    );
    return () => clearTimeout(searchTimeout); // this also to clear time out
  }, [filterName]);

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const sortingCardData = (value) => {
    // the reducer is related to sorting the list. If empty issueTimestamp will be the latest one;
    const reducer = value.map((x) => ({
      cardId: x.cardId ? x.cardId : null,
      isValid: x.isValid,
      issueReason: x.issueReason,
      issueTimestamp: x.issueTimestamp ? x.issueTimestamp : null,
      validFrom: x.validFrom,
      validTo: x.validTo,
      _id: x._id,
    }));
    const dataValue = reducer.sort((a, b) => (b.issueTimestamp === null) - (a.issueTimestamp === null) || new Date(b.issueTimestamp) - new Date(a.issueTimestamp));
    return dataValue;
  };

  const handleClickEdit = (value) => {
    setEditValue(value);
    setCardDisplay({
      cardId: value.card && value.card.cardId ? value.card.cardId : null,
      status: value.card && value.card.status ? value.card.status : null,
      validFrom: value.card && value.card.validFrom ? value.card.validFrom : null,
      validTo: value.card && value.card.validTo ? value.card.validTo : null,
    });
    if (value.cards) {
      setDataSet(sortingCardData(value.cards));
    }
    setEditState(true);
    setModalClick(true);
  };

  const handleBulkExpCard = async () => {
    try {
      const reducer = {
        workers: selected.map((x) => ({
          oid: x._id,
          card: {
            validTo: cardExpiryDate,
          },
        })),
      };
      const result = await APIBulkUpdateWorker(reducer, setLoading, token);
      // console.log('checked on result bulk update', result);
      if (result.data && result.data.status === 200) {
        setLoading(false);
        apiCalled();
        setOpen(true);
        setErrMsg('Successfully updated card expiry dates.');
        setVariant('success');
        setSelected([]);
        setOpenBulkUpdatePopUp(false);
      } else {
        setLoading(false);
        setSelected([]);
        setOpen(true);
        setVariant('error');
        setErrMsg(`Error! ${result.response.data.message}`);
        setOpenBulkUpdatePopUp(false);
      }
    } catch (err) {
      setLoading(false);
      setSelected([]);
      setOpen(true);
      setVariant('error');
      setErrMsg(`Error! ${err}`);
      setOpenBulkUpdatePopUp(false);
    }
  };

  const handleUpdateBulkPrint = async () => {
    try {
      const getListWorker = selected.map((x) => x._id);
      const stringListWorker = getListWorker.join();
      const result = await APICardReadinessToPrint(stringListWorker, setLoading, token);
      if (result.data && result.data.status === 200) {
        const { data } = result.data;
        if (data.pass) {
          apiBulkPrintStatusChange();
        } else {
          setLoading(false);
          // this to add in the modal
          if (data.failedWorkers.length > 0) {
            setDisplayFailedWorker(data.failedWorkers.map((x) => x.passportNumber));
          }
          setModalFailedWorker(true);
        }
      } else {
        setLoading(false);
        // setSelected([]);
        setOpen(true);
        setVariant('error');
        setErrMsg(`Error! ${result.response.data.message}`);
        setOpenBulkUpdatePopUp(false);
      }
    } catch (err) {
      setLoading(false);
      setSelected([]);
      setOpen(true);
      setVariant('error');
      setErrMsg(`Error! ${err}`);
      setOpenBulkUpdatePopUp(false);
    }
  };

  const removeModalState = () => {
    setModalFailedWorker(!modalFailedWorker);
    setSelected([]);
  };

  const areAllElementsInArrayAInArrayB= (arrayA, arrayB) => {
    return arrayA.every(element => arrayB.find((x) => x._id === element._id));
  };

  useEffect(() => {
    const result = areAllElementsInArrayAInArrayB(workerData, selected);
    setCheckedState(result);
    handleIndeterminateState(workerData, selected);
  }, [workerData, handleSelectAllClick, apiCalled]);

  return (
    <Page title="Worker">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Worker Management
          </Typography>
          {loading && <CircularProgress color="success" sx={tableCircularLoadingStyle} />}
          <Button variant="contained" component={RouterLink} disabled={selected.length > 1 ? true : !disabledButton} to="#" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => handleOpen()}>
            New Worker
          </Button>
        </Stack>
        <SnackBar alert={variant} handleClose={() => setOpen(false)} open={open} errorMsg={errMsg} />
        <Card>
          <TableListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} labelName="Search Passport No..." popupfilter={setOpenFilterPopUp} displayFilter selected={selected} popupBulkUpdate={setOpenBulkUpdatePopUp} functionUpdatePrint={handleUpdateBulkPrint} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableListHead
                  order={order}
                  checkedState={checkedState}
                  orderBy={orderBy}
                  workerData={workerData}
                  selected={selected}
                  headLabel={TABLE_HEAD}
                  indeterminateState={indeterminateState}
                  rowCount={workerData.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  component="Worker"
                  onSelectAllClick={handleSelectAllClick}
                />
                <MenuPopover open={Boolean(openFilterPopUp)} anchorEl={openFilterPopUp} onClose={closePopup} styleWidth={500}>
                  <Box>
                    <Typography variant="subtitle1" sx={{ paddingTop: 1, paddingBottom: 2, paddingLeft: 1 }}>Filter By</Typography>
                    <TagsInput fullWidth variant="outlined" id="name" name="name" placeholder="add tags" label="name" dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="name" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                    <TagsInput fullWidth variant="outlined" id="phone" name="phone" placeholder="add tags" label="phone" dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="phone" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                    <TagsInput fullWidth variant="outlined" id="gender" name="gender" placeholder="add tags" label="gender" dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="gender" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                    <TagsInput fullWidth variant="outlined" id="nationality" name="nationality" placeholder="add tags" label="nationality" dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="nationality" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                    <TagsInput fullWidth variant="outlined" id="company" name="company" placeholder="add tags" label="company" dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="company" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                    <TagsInput fullWidth variant="outlined" id="email" name="email" placeholder="add tags" label="email" dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="email" setGetFilter={setGetFilter} getFilter={getFilter} />
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <Button variant="outlined" sx={{ width: 110, marginRight: '8px' }} component={RouterLink} to="#" onClick={() => clearFilterButton()}>
                      Clear Filter
                    </Button>
                    <Button variant="contained" sx={{ width: 110 }} component={RouterLink} to="#" onClick={() => filterButton()}>
                      Filter
                    </Button>
                  </Box>
                </MenuPopover>
                <TableBody>
                  {workerData.length > 0 && workerData.map((row, index) => {
                    const {
                      _id, gender, passportNumber, passportExpiry, company,
                    } = row;

                    const isItemSelected = selected.findIndex((x) => x._id === _id) !== -1;

                    const getFullName = (value) => {
                      const fields = [];

                      if (value.firstName) {
                        fields.push(value.firstName);
                      }

                      if (value.middleName) {
                        fields.push(value.middleName);
                      }

                      if (value.lastName) {
                        fields.push(value.lastName);
                      }

                      if (fields.length > 0) {
                        return fields.join(' ');
                      }
                      return '-';
                    };

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox" onClick={(event) => handleSelectClick(event, row)}>
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': passportNumber,
                            }}
                          />
                        </TableCell>
                        <TableCell align="left" onClick={() => handleClickEdit(row)} sx={{ cursor: 'pointer' }}>{getFullName(row)}</TableCell>
                        <TableCell align="left" onClick={() => handleClickEdit(row)} sx={{ cursor: 'pointer' }}>{gender || '-'}</TableCell>
                        <TableCell align="left" onClick={() => handleClickEdit(row)} sx={{ cursor: 'pointer' }}>{passportNumber}</TableCell>
                        <TableCell align="left" onClick={() => handleClickEdit(row)} sx={{ cursor: 'pointer' }}>{passportExpiry ? moment(passportExpiry).format('DD-MM-YYYY') : '-'}</TableCell>
                        <TableCell align="left" onClick={() => handleClickEdit(row)} sx={{ cursor: 'pointer' }}>{companyName(company)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <ModalParent modalClick={modalClick} removeAllState={removeAllState}>
              <FormWorker
                companyList={companyList}
                style={styleDesktop}
                editState={editState}
                setEditState={setEditState}
                setEditValue={setEditValue}
                editValue={editValue}
                removeAllState={removeAllState}
                setOpen={setOpen}
                setErrMsg={setErrMsg}
                setVariant={setVariant}
                apiCalled={apiCalled}
                workerPrivi={workerPrivi}
                setcallAPI={setcallAPI}
                setDataSet={setDataSet}
                dataSet={dataSet}
                sortingCardData={sortingCardData}
                cardDisplay={cardDisplay}
                setCardDisplay={setCardDisplay}
              />
            </ModalParent>
          </Scrollbar>
          <Box sx={{ margin: '16px 8px' }}>
            <ListPagination count={pageTotal} page={page} handlePagination={handlePagination} limitValue={rowsPerPage} listofLimit={rowsLimit} handleSelection={handleSelection} />
          </Box>
        </Card>
      </Container>
      <div style={{ display: 'none' }}>
        <BulkPrintJob
          details={selected}
          ref={componentRef1}
          borderDisplay={borderDisplay}
        />
      </div>
      {
        openBulkUpdatePopUp && (
          <CardExpiryModal
            setViewOpen={setOpenBulkUpdatePopUp}
            viewOpen={openBulkUpdatePopUp}
            widths="40vw"
            cardExpiryDate={cardExpiryDate}
            setCardExpiryDate={setCardExpiryDate}
            source="bulkUpdate"
            bulkExpDateFunction={handleBulkExpCard}
            borderDisplay={borderDisplay}
            setBorderDisplay={setBorderDisplay}
            editValue={selected}
          />
        )
      }
      {
        modalFailedWorker && (
          <ModalParent modalClick={modalFailedWorker} removeAllState={removeModalState}>
            <Box sx={style}>
              <Card sx={{
                width: '35vw', padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center',
              }}
              >
                {/* <></> */}
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      display: 'flex', width: '100%', justifyContent: 'center', marginBottom: '8px',
                    }}
                    variant="subtitle1"
                  >
                    Unable to print due to below workers not having an expiry date
                  </Typography>
                  <Icon
                    sx={{
                      color: '#939598', fontWeight: 700, cursor: 'pointer', position: 'absolute', top: 16, right: 16,
                    }}
                    onClick={() => { setModalFailedWorker(false); setSelected([]); }}
                  >
                    <CloseRoundedIcon />
                  </Icon>
                </div>
                <Paper style={{ maxHeight: 200, overflow: 'auto' }}>
                  {
                      displayFailedWorker.map((x) => (
                        <Typography
                          sx={{
                            display: 'flex', width: '100%', marginBottom: '8px',
                          }}
                          variant="subtitle2"
                        >
                          {x}
                        </Typography>
                      ))
                    }
                </Paper>

                {/* <Stack
                  spacing={2}
                  direction="row"
                  sx={{
                    marginTop: '16px',
                  }}
                >
                  <Button variant="outlined" onClick={() => setModalFailedWorker(false)}>Confirm</Button>
                </Stack> */}
              </Card>
            </Box>
          </ModalParent>
        )
      }
    </Page>
  );
}
