/* eslint-disable no-irregular-whitespace */
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage, getDownloadURL, ref } from 'firebase/storage';
// ​​import { GoogleAuthProvider, getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY_DEV,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN_DEV,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL_DEV,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID_DEV,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET_DEV,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID_DEV,
  appId: process.env.REACT_APP_FIREBASE_APPID_DEV,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID_DEV,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
export {
  auth, storage, getDownloadURL, ref,
};
