/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import React, { useState, useEffect, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
} from '@mui/material';
// components
import Page from '../components/Page';
// import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import { TableListHead, TableListToolbar } from '../sections/@dashboard/user';
import { APIFetchUser, APIFetchCompany } from '../utils/apiCalled';
import SnackBar from '../components/SnackBar';
import ModalParent from '../components/ModalParent';
import FormUser from '../components/dashboard/user/FormUser';
import { tableCircularLoadingStyle, rowsLimit } from '../utils/config';
import ListPagination from '../components/ListPagination';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'fullName', label: 'Full Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'contactNo', label: 'Contact No', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
];

// ----------------------------------------------------------------------

const styleDesktop = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75vw',
  backgroundColor: 'white',
  boxShadow: 24,
  padding: '32px',
  borderRadius: '16px',
};

const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: '16px',
  maxHeight: '90%',
  width: '80vw',
  overflow: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

export default function User() {
  const token = Cookies.get('token');
  const role = Cookies.get('role');
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');
  const [callAPI, setcallAPI] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(null); // this for filterTimeout
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageTotal, setPageTotal] = useState(null);
  const [userData, setUserData] = useState([]); // list of the userData take from fetchWorker API
  const [loading, setLoading] = useState(false); // all the loading state (when called the API)
  const [open, setOpen] = useState(false); // open snackbar
  const [errMsg, setErrMsg] = useState(''); // error message snackbar
  const [variant, setVariant] = useState('');
  const [modalClick, setModalClick] = useState(false);
  const [editValue, setEditValue] = useState(null);
  const [editState, setEditState] = useState(false);
  const [companyList, setCompanyList] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const apiCalled = async (nameParams) => {
    try {
      const result = await APIFetchUser(nameParams, rowsPerPage, page, setLoading, token);
      if (result.data && result.data.status === 200) {
        const { data, totalPage, user } = result.data;
        Cookies.set('role', user.role);
        setPageTotal(totalPage);
        setUserData(data);
        setLoading(false);
      } else {
        setLoading(false);
        setOpen(true);
        setVariant('error');
        setErrMsg(`Error! ${result.response.data.message}`);
      }
    } catch (err) {
      setLoading(false);
      setVariant('error');
      setOpen(true);
      setErrMsg(`Error! : ${err} Please try again`);
    }
  };

  const apiCompanyCalled = async (params) => {
    try {
      const result = await APIFetchCompany(params, null, 1000, page, setLoading, token);
      if (result.data && result.data.status === 200) {
        const { data } = result.data;
        const reducer = data.length > 0 ? data.map((x) => ({
          id: x._id && x._id,
          name: x.name && x.name,
          address: x.address && x.address,
          industry: x.industry && x.industry,
          representatives: x.representatives && x.representatives,
        })) : [];
        setCompanyList(reducer);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      // console.log('Company API Called Err');
      console.e.log(err);
    }
  };

  useEffect(() => {
    if (token) {
      apiCalled();
      apiCompanyCalled();
    }
    return () => {
      setUserData([]);
    };
  }, []);

  // this is for filter
  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    // this to call api and setTimeout to 0.2 sec
    setSearchTimeout(
      setTimeout(() => {
        apiCalled(filterName);
        setPage(1);
      }, 200),
    );
    return () => clearTimeout(searchTimeout); // this also to clear time out
  }, [filterName]);

  const removeAllState = () => {
    setModalClick(false);
    setEditValue(null);
    setEditState(false);
  };

  // this to call when click limit or pagination
  useEffect(() => {
    if (callAPI) {
      apiCalled('', rowsPerPage, page, setLoading);
      setcallAPI(false);
    }
  }, [callAPI]);

  const handleOpen = () => {
    setModalClick(true);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleClickEdit = (value) => {
    setEditValue(value);
    setEditState(true);
    setModalClick(true);
  };

  const handleSelection = (e) => {
    setRowsPerPage(e.target.value);
    setPage(1);
    setcallAPI(true);
  };

  const handlePagination = (e, pageNo) => {
    setPage(pageNo);
    setcallAPI(true);
  };


  const changeDisplayRole = (cmpRole) => {
    switch (cmpRole) {
      case 'EMPLOYER':
        return 'Company Representative';
      case 'FIC_SUPERADMIN':
        return 'FIC Superadmin';
      case 'FIC_ADMIN':
        return 'FIC Admin';
      case 'ENFORCEMENT_OFFICER':
        return 'Enforcement Officer';
      default:
        return null;
    }
  };

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User Management
          </Typography>
          {loading && <CircularProgress color="success" sx={tableCircularLoadingStyle} />}
          <Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => handleOpen()}>
            New User
          </Button>
        </Stack>
        <SnackBar alert={variant} handleClose={() => setOpen(false)} open={open} errorMsg={errMsg} />
        <Card>
          <TableListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} labelName="Search Name..." />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={userData.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  // onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {userData.map((row) => {
                    const {
                      _id, email, fullName, contactCode, contactNo, role,
                    } = row;
                    const isItemSelected = selected.indexOf(_id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell align="left" sx={{ cursor: 'pointer' }} onClick={() => handleClickEdit(row)}>{fullName || '-'}</TableCell>
                        <TableCell align="left" sx={{ cursor: 'pointer' }} onClick={() => handleClickEdit(row)}>{email || '-'}</TableCell>
                        <TableCell align="left" sx={{ cursor: 'pointer' }} onClick={() => handleClickEdit(row)}>{contactCode && contactNo ? `${contactCode}${contactNo}` : '-'}</TableCell>
                        <TableCell align="left" sx={{ cursor: 'pointer' }} onClick={() => handleClickEdit(row)}>{role ? changeDisplayRole(role) : '-'}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <ModalParent modalClick={modalClick} removeAllState={removeAllState}>
              <FormUser
                style={styleDesktop}
                editState={editState}
                setEditState={setEditState}
                setEditValue={setEditValue}
                editValue={editValue}
                removeAllState={removeAllState}
                setOpen={setOpen}
                setErrMsg={setErrMsg}
                setVariant={setVariant}
                apiCalled={apiCalled}
                apiCompanyCalled={apiCompanyCalled}
                companyList={companyList}
              />
            </ModalParent>
          </Scrollbar>
          <Box sx={{ margin: '16px 8px' }}>
            <ListPagination count={pageTotal} handlePagination={handlePagination} limitValue={rowsPerPage} listofLimit={rowsLimit} handleSelection={handleSelection} />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
