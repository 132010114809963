import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const parseJwt = (value) => {
  try {
    return JSON.parse(atob(value.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = (props) => {
  const location = useLocation();

  useEffect(() => {
    const user = Cookies.get('role');
    const token = Cookies.get('token');

    if (user) {
      const decodedJwt = parseJwt(token);

      if (decodedJwt) {
        if (decodedJwt.exp < Date.now()) {
          // if (decodedJwt.exp < (decodedJwt.exp + 1)) {
          props.logOut();
        }
      }
    }
  }, [location, props]);
};

export default AuthVerify;
