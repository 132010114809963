/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { filter } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Box,
  Modal,
  Table,
  TextField,
  Autocomplete,
  Divider,
  Stack,
  Typography,
  Button,
  FormControl,
  Card,
  TablePagination,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
} from '@mui/material';
import Cookies from 'js-cookie';
import Icon from '@mui/material/Icon';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Scrollbar from '../../Scrollbar';
import { TableListHead } from '../../../sections/@dashboard/user';
import SnackBar from '../../SnackBar';

import {
  AllMandatoryFilled,
  requiredField,
  workerIssueReasonList,
} from '../../../utils/config';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  width: '100vw',
  height: '100vh',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  // pt: 2,
  // px: 4,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.passportNumber.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
  { id: 'cardId', label: 'Card ID', alignRight: false },
  { id: 'issueReason', label: 'Issue Reason', alignRight: false },
  { id: 'validFrom', label: 'Valid From', alignRight: false },
  { id: 'validTo', label: 'Valid To', alignRight: false },
  { id: 'isValid', label: 'Is Valid', alignRight: false },
  { id: 'issueTimestamp', label: 'Issue Time Stamp', alignRight: false },
];

// Deprecated!
export default function ModalCardWorker({
  setViewOpen, setEditValue, detailsPageAPICalled, setcallAPI, data, viewOpen, widths, heights, workerId, workerPrivi, setDataSet, dataSet, sortingCardData,
}) {
  const handleClose = () => {
    setViewOpen(false);
  };
  const token = Cookies.get('token');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [dataState, setdataState] = useState(data);
  const [filterName, setFilterName] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false); // open snackbar
  const [errMsg, setErrMsg] = useState(''); // error message snackbar
  const [variant, setVariant] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [cardValue, setCardValue] = useState({
    issueReason: null,
    validFrom: null,
    validTo: null,
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataSet.length) : 0;

  const filteredUsers = applySortFilter(dataSet, getComparator(order, orderBy), filterName);

  const handleChangeCard = (e, value, name, reason) => {
    if (reason === 'clear') {
      setCardValue({ ...cardValue, [name]: '' });
    } else {
      setCardValue({ ...cardValue, [name]: value });
    }
  };

  const cardChecking = () => {
    const checkedValidation = AllMandatoryFilled(requiredField.card, cardValue);
    return checkedValidation;
  };

  useEffect(() => {
    if (cardChecking()) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [cardValue]);

  const createCardAPI = async (id, params) => {
    try {
      const reducer = {
        issueReason: params.issueReason.value,
        validFrom: params.validFrom,
        validTo: params.validTo,
      };
      const result = await APICreateCard(reducer, id, setLoading, token);
      // console.log('result', result);
      if (result.data && result.data.status === 200) {
        const { cards } = result.data.data;
        setLoading(false);
        setOpen(true);
        setErrMsg('Thank you! Card added succesful!');
        setVariant('success');
        // detailsPageAPICalled(id);
        // setdataState(cards);
        setcallAPI(true);
        setDataSet(sortingCardData(cards));
        // setApiCalledComponent(true);
      } else {
        setLoading(false);
        setOpen(true);
        setErrMsg(`Error! ${result.response.data.message}`);
        setVariant('error');
      }
    } catch (err) {
      setLoading(false);
      setOpen(true);
      setErrMsg(`Error!: ${err}`);
      setVariant('error');
    }
  };

  const issueReasonReducer = (value) => {
    switch (value) {
      case 'first_card':
        return 'First Card';
      case 'lost_card':
        return 'Lost Card';
      case 'damaged_card':
        return 'Damaged Card';
      case 'previous_card_expired':
        return 'Previous Card Expired';
      default:
        return 'First Card';
    }
  };

  const isValidField = (value) => {
    switch (value) {
      case true:
        return 'Yes';
      case false:
        return 'No';
      default:
        return '-';
    }
  };

  // console.log(filteredUsers);

  return (
    <Modal
      open={viewOpen}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{ ...style }}>
        <SnackBar alert={variant} handleClose={() => setOpen(false)} open={open} errorMsg={errMsg} />
        <Card sx={{ width: widths, height: heights, padding: 4 }}>
          <Icon
            sx={{
              color: '#939598', fontWeight: 700, cursor: 'pointer', position: 'absolute', top: 16, right: 16,
            }}
            onClick={handleClose}
          >
            <CloseRoundedIcon />
          </Icon>
          <Typography
            sx={{
              display: 'flex', width: '100%', justifyContent: 'center', marginBottom: '24px',
            }}
            variant="subtitle1"
          >
            Card List
          </Typography>

          {
            workerPrivi.length > 0 && workerPrivi[0].role.update && (
            <Box sx={{
              display: 'grid',
              gridAutoColumns: '1fr',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              gridRTemplateRows: '1fr',
              gap: '0px 12px',
              gridTemplateAreas:
                '. . . .',
              marginBottom: '24px',
            }}
            >
              <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
                <Autocomplete
                  value={cardValue.issueReason}
                  getOptionLabel={(option) => option.name ?? option}
                  onChange={(e, newValue, reason) => {
                    handleChangeCard(e, newValue, 'issueReason', reason);
                  }}
                  options={workerIssueReasonList}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      labelId="demo-simple-select-standard-label"
                      label="Card Issue Reason"
                    />
                  )}
                />
              </FormControl>
              <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Valid Card From"
                    inputFormat="dd/MM/yyyy"
                    value={cardValue.validFrom}
                    disablePast
                    onChange={(newValue) => {
                      const dateRangeOneYear = moment(newValue).add(1, 'years').format(); // auto populate the date range by one year
                      setCardValue({ ...cardValue, validFrom: newValue, validTo: dateRangeOneYear });
                    }}
                    renderInput={(params) => <TextField {...params} required />}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Valid Card To"
                    inputFormat="dd/MM/yyyy"
                    value={cardValue.validTo}
                    disablePast
                    onChange={(newValue) => {
                      setCardValue({ ...cardValue, validTo: newValue });
                    }}
                    renderInput={(params) => <TextField {...params} required />}
                  />
                </LocalizationProvider>
              </FormControl>
              <Stack
                spacing={2}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end',
                }}
              >
                {/* <Button variant="contained" disabled={!btnDisabled} sx={{ width: 90, height: '56px' }} onClick={() => createCardAPI(workerId, cardValue)}>Add</Button> */}
              </Stack>
            </Box>
            )
          }
          <Divider />
          <Scrollbar>
            <TableContainer>
              <Table>
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataSet.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  // onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const isItemSelected = selected.indexOf(row.cardId) !== -1;

                    return (
                      <TableRow
                        hover
                        key={row.cardId}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell align="left">{row.cardId ? row.cardId : '-'}</TableCell>
                        <TableCell align="left">{issueReasonReducer(row.issueReason)}</TableCell>
                        <TableCell align="left">{moment(row.validFrom).format('DD-MM-YYYY')}</TableCell>
                        <TableCell align="left">{moment(row.validTo).format('DD-MM-YYYY')}</TableCell>
                        <TableCell align="left">{isValidField(row.isValid)}</TableCell>
                        <TableCell align="left">{row && row.issueTimestamp ? row.issueTimestamp : '-'}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dataSet.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Box>
    </Modal>
  );
}

ModalCardWorker.propTypes = {
  setViewOpen: PropTypes.func,
  viewOpen: PropTypes.bool,
  widths: PropTypes.string,
  heights: PropTypes.string,
  data: PropTypes.shape(),
  workerId: PropTypes.string,
  apiCalled: PropTypes.func,
  workerPrivi: PropTypes.array,
  setEditValue: PropTypes.func,
  setcallAPI: PropTypes.func,
  detailsPageAPICalled: PropTypes.func,
  setDataSet: PropTypes.func,
  dataSet: PropTypes.array,
  sortingCardData: PropTypes.func,
};
