import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@mui/material';

const ModalParent = ({ children, modalClick, removeAllState }) => (
  <Modal
    open={modalClick}
    onClose={removeAllState}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    { children }
  </Modal>
);

ModalParent.propTypes = {
  children: PropTypes.element,
  modalClick: PropTypes.bool,
  removeAllState: PropTypes.func,
};

export default ModalParent;
