/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable no-useless-escape */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Base64 } from 'js-base64';
import {
  TextField,
  Box,
  Typography,
  FormControl,
  Stack,
  Button,
  Divider,
  FormGroup,
  Checkbox,
  FormLabel,
  FormControlLabel,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import Cookies from 'js-cookie';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MuiPhoneNumber from 'material-ui-phone-number';
import _ from 'lodash';
import {
  APICreateUser,
  APIUpdateUser,
  APIDeleteUser,
} from '../../../utils/apiCalled';
import { APIGetCountry } from '../../../utils/countryAPICalled';
import {
  AllMandatoryFilled,
  requiredField,
  genderList,
  statusList,
  userTypelist,
  circularLoadingStyle,
//   workerIssueReasonList,
} from '../../../utils/config';
import DeleteModal from '../../DeleteModal';

const FormUser = ({
  style, editState, setEditValue, editValue, removeAllState, setOpen, setErrMsg, setVariant, apiCalled, companyList, apiCompanyCalled,
}) => {
  const phoneConverter = (contactCode, contactNo) => {
    const phoneNumber = `${contactCode}${contactNo}`;
    return phoneNumber;
  };

  const getCompanyDetails = (value) => {
    const filterCompany = companyList.filter((x) => x.representatives.some((c) => c._id === value));
    if (filterCompany.length > 0) {
      return filterCompany[0];
    }
    return '';
  };

  const token = Cookies.get('token');

  const [userInput, setUserInput] = useState({
    firstName: editState ? editValue.firstName : null,
    lastName: editState ? editValue.lastName : null,
    fullName: editState ? editValue.fullName : null,
    contactCode: editState ? editValue.contactCode : null,
    contactNo: editState ? editValue.licenseImageURL : null,
    phoneNumber: editState ? phoneConverter(editValue.contactCode, editValue.contactNo) : null,
    password: editState ? 'dummytext' : null,
    confirmPassword: editState ? 'dummytext' : null,
    email: editState ? editValue.email : null,
    role: editState ? editValue.role : null,
    privilege: editState ? editValue.privilege : null,
    company: editState ? { name: getCompanyDetails(editValue._id).name, companyOid: getCompanyDetails(editValue._id).id } : { name: null, companyOid: null },
    icNumber: editState ? editValue.icNumber : null,
  });

  const [workerPrivileges, setWorkerPrivileges] = useState({
    create: userInput.privilege ? userInput.privilege.create : false,
    read: userInput.privilege ? userInput.privilege.read : false,
    update: userInput.privilege ? userInput.privilege.update : false,
    delete: userInput.privilege ? userInput.privilege.delete : false,
  });

  const [cardPrivileges, setCardPrivileges] = useState({
    printCard: userInput.privilege ? userInput.privilege.printCard : false,
    verifyCard: userInput.privilege ? userInput.privilege.verifyCard : false,
    writeCard: userInput.privilege ? userInput.privilege.writeCard : false,
    delete: userInput.privilege ? userInput.privilege.delete : false,
  });

  //   const [countryList, setCountryList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [errPassword, setErrPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [deleteModal, setDeleteModal] = useState(false);

  const fetchData = () => {
    if (editValue) {
      setLoading(true);
      setUserInput({
        firstName: editValue.firstName,
        lastName: editValue.lastName,
        contactCode: editValue.contactCode,
        contactNo: editValue.contactNo,
        phoneNumber: phoneConverter(editValue.contactCode, editValue.contactNo),
        email: editValue.email,
        password: 'dummytext',
        confirmPassword: 'dummytext',
        role: editValue.role,
        privilege: editValue.privilege,
        company: { name: getCompanyDetails(editValue._id).name, companyOid: getCompanyDetails(editValue._id).id },
        icNumber: editValue.icNumber,
      });
    }
  };

  const handleTextFieldChange = (e) => {
    setUserInput({ ...userInput, [e.target.name]: e.target.value });
  };

  const handleAutocompleteChange = (e, value, name, reason) => {
    if (reason === 'clear') {
      setUserInput({ ...userInput, [name]: '' });
      setWorkerPrivileges({
        create: null,
        read: null,
        update: null,
        delete: null,
      });
      setCardPrivileges({
        printCard: null,
        verifyCard: null,
        writeCard: null,
        delete: null,
      });
    } else {
      setUserInput({ ...userInput, [name]: value.value });
      if (value.value === 'FIC_ADMIN') {
        setWorkerPrivileges({
          create: true,
          read: true,
          update: true,
          delete: true,
        });
        setCardPrivileges({
          printCard: true,
          verifyCard: true,
          writeCard: true,
          delete: true,
        });
      } else {
        setWorkerPrivileges({
          create: null,
          read: null,
          update: null,
          delete: null,
        });
        setCardPrivileges({
          printCard: null,
          verifyCard: null,
          writeCard: null,
          delete: null,
        });
      }
    }
  };

  const handlePhoneNumber = (e, name, country) => {
    const removeSymbol = e.replace('+', ''); // remove the +
    const slicePhone = removeSymbol.slice(2); // slice the phone
    // console.log('removeSymbol', slicePhone);
    setUserInput({
      ...userInput, [name]: e, contactCode: country.dialCode, contactNo: slicePhone,
    });
  };

  const handleChangeCompanyAutocomplete = (e, value, name, reason) => {
    if (reason === 'clear') {
      setUserInput({ ...userInput, [name]: { name: '', companyOid: '' } });
    } else {
      setUserInput({ ...userInput, [name]: { name: value.name, companyOid: value.id } });
    }
  };

  const removeState = () => {
    removeAllState();
    setUserInput({
      firstName: null,
      lastName: null,
      contactCode: null,
      contactNo: null,
      phoneNumber: null,
      password: null,
      confirmPassword: null,
      email: null,
      role: null,
      company: null,
      privilege: null,
      icNumber: null,
    });
    setWorkerPrivileges({
      create: null,
      read: null,
      update: null,
      delete: null,
    });
    setCardPrivileges({
      printCard: null,
      verifyCard: null,
      writeCard: null,
      delete: null,
    });
  };

  const checkValidationUser = () => {
    const checkedValidation = AllMandatoryFilled(requiredField.user, userInput);
    // console.log('checkedValidation', checkedValidation);
    // console.log(requiredField.worker);
    return checkedValidation;
  };

  const checkEmailValidation = () => {
    const emRegex = new RegExp(/^(([^<>()[\].,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const validation = userInput && userInput.email ? emRegex.test(userInput.email) : false;
    return validation;
  };

  const checkDetailChanges = () => {
    const input = {};

    if (userInput.firstName !== editValue.firstName) {
      input.firstName = userInput.firstName;
    }
    if (userInput.lastName !== editValue.lastName) {
      input.lastName = userInput.lastName;
    }
    if (userInput.contactNo !== editValue.contactNo) {
      input.contactNo = userInput.contactNo;
    }
    if (userInput.email !== editValue.email) {
      input.email = userInput.email;
    }
    if (userInput.password !== 'dummytext') {
      input.password = userInput.password;
    }
    if (userInput.confirmPassword !== 'dummytext') {
      input.confirmPassword = userInput.confirmPassword;
    }
    if (userInput.icNumber !== editValue.icNumber) {
      input.icNumber = userInput.icNumber;
    }
    if (userInput.role !== editValue.role) {
      input.role = userInput.role;
    }
    if (userInput.role === 'EMPLOYER') {
      if (userInput.company.companyOid !== getCompanyDetails(editValue._id).id) {
        input.companyOid = userInput.company.companyOid;
      }
    }
    if (userInput.role === 'FIC_ADMIN') {
      if (!_.isEqual(userInput.privilege, editValue.privilege)) {
        input.privilege = userInput.privilege;
      }
    }

    const checking = Object.keys(input).length > 0;
    return checking;
  };

  const checkPassword = () => {
    const checking = (userInput.password === userInput.confirmPassword) && (userInput.password.length >= 6);

    return checking;
  };

  useEffect(() => {
    // console.log('editState', editState);
    if (editState) {
      if (checkValidationUser() && checkDetailChanges() && checkEmailValidation() && checkPassword()) {
        setBtnDisabled(true);
      } else {
        setBtnDisabled(false);
      }
    } else if (checkValidationUser() && checkEmailValidation() && checkPassword()) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [userInput, workerPrivileges, cardPrivileges]);

  useEffect(() => {
    fetchData();
    setLoading(false);
  }, [editValue]);

  useEffect(() => {
    if (userInput.password) {
      if (userInput.password.length >= 6 && userInput.password === userInput.confirmPassword) {
               setErrPassword({ ...errPassword, password: false, confirmPassword: false });
      } else {
               setErrPassword({ ...errPassword, password: true, confirmPassword: true });
      }
    }
  }, [userInput]);

  const handleWorkerManagement = (e) => {
    setUserInput({ ...userInput, privilege: { ...userInput.privilege, [e.target.name]: e.target.checked } });
    // setWorkerPrivileges({ ...workerPrivileges, [e.target.name]: e.target.checked });
  };

  const handleCardManagement = (e) => {
    setUserInput({ ...userInput, privilege: { ...userInput.privilege, [e.target.name]: e.target.checked } });
    // setCardPrivileges({ ...cardPrivileges, [e.target.name]: e.target.checked });
  };

  const reducer = (inputUser) => {
    const input = {};
    if (editState) {
      if (inputUser.firstName !== editValue.firstName) {
        input.firstName = inputUser.firstName;
      }
      if (inputUser.lastName !== editValue.lastName) {
        input.lastName = inputUser.lastName;
      }
      if (inputUser.contactCode) {
        if (parseInt(inputUser.contactCode, 10) !== editValue.contactCode) {
          input.contactCode = parseInt(inputUser.contactCode, 10);
        }
      }
      if (inputUser.contactNo) {
        if (parseInt(inputUser.contactNo, 10) !== editValue.contactNo) {
          input.contactNo = parseInt(inputUser.contactNo, 10);
        }
      }
      if (inputUser.company) {
        input.companyOid = inputUser.company.companyOid;
      }
      if (inputUser.email !== editValue.email) {
        input.email = Base64.encode(inputUser.email);
      }
      if (inputUser.password !== 'dummytext') {
        input.password = Base64.encode(inputUser.password);
      }
      if (inputUser.role !== editValue.role) {
        input.role = inputUser.role;
      }
      // input.privilege = userInput.privilege;
      if (userInput.role === 'FIC_ADMIN') {
        if (!_.isEqual(userInput.privilege, editValue.privilege)) {
          input.privilege = userInput.privilege;
        }
        input.companyOid = null;
      }
      if (inputUser.icNumber !== editValue.icNumber) {
        input.icNumber = inputUser.icNumber;
      }
    } else {
      input.firstName = inputUser.firstName;
      input.lastName = inputUser.lastName;
      input.fullName = `${inputUser.firstName} ${inputUser.lastName}`,
      input.contactCode = inputUser.contactCode;
      input.contactNo = inputUser.contactNo;
      input.email = Base64.encode(inputUser.email);
      input.password = Base64.encode(inputUser.password);
      input.role = inputUser.role;
      if (inputUser.role === 'FIC_ADMIN') {
        input.privilege = {
          create: true,
          read: true,
          update: true,
          delete: true,
          writeCard: false,
          printCard: true,
          verifyCard: true,
          }
      }
      input.companyOid = inputUser.company.companyOid;
      input.icNumber = inputUser.icNumber;
    }

    return input;
  };

  const handleDeleteClose = () => {
    setDeleteModal(false);
  };

  const apiAddEditCalled = async (value, id) => {
    try {
      const result = editState ? await APIUpdateUser(value, id, setLoading, token) : await APICreateUser(value, setLoading, token);
      if (result.data && result.data.status === 200) {
        Cookies.set('role', result.data.user.role);
        setLoading(false);
        setOpen(true);
        setErrMsg('Thank you! Your submission has been sent!');
        setVariant('success');
        apiCalled();
        apiCompanyCalled();
        removeAllState();
      } else {
        setLoading(false);
        setOpen(true);
        setErrMsg(`Error! ${result.response.data.message}`);
        setVariant('error');
      }
    } catch (err) {
      setLoading(false);
      setOpen(true);
      setErrMsg(`Error!: ${err}`);
      setVariant('error');
    }
  };

  const deleteButton = async (userId) => {
    // console.log(userId);
    try {
      const result = await APIDeleteUser(userId, setLoading, token);
      // console.log(result);
      if (result.data && result.data.status === 200) {
        Cookies.set('role', result.data.user.role);
        setLoading(false);
        setOpen(true);
        setErrMsg('Thank you! Your submission has been sent!');
        setVariant('success');
        apiCalled();
        apiCompanyCalled();
        removeAllState();
      } else {
        setLoading(false);
        setOpen(true);
        setErrMsg(`Error! ${result.response.data.message}`);
        setVariant('error');
      }
    } catch (err) {
      setLoading(false);
      setOpen(true);
      setErrMsg(`Error!: ${err}`);
      setVariant('error');
    }
  };

  const handleSaveButton = () => {
    if (editState) {
      const { _id } = editValue;
      const afterReducerInput = reducer(userInput, workerPrivileges, cardPrivileges);
      // console.log('afterReducerInputAdd', afterReducerInput);
      apiAddEditCalled(afterReducerInput, _id);
      // console.log(userInput);
      // console.log('edit', afterReducerInput, _id);
    } else {
      const afterReducerInput = reducer(userInput, workerPrivileges, cardPrivileges);
      // console.log('afterReducerInputAdd', afterReducerInput);
      apiAddEditCalled(afterReducerInput, '');
      // console.log('add', afterReducerInput);
    }
  };

  const valueChangeDropDownList = (value) => {
    switch (value) {
      case 'FIC_SUPERADMIN':
        return 'FIC Superadmin';
      case 'FIC_ADMIN':
        return 'FIC Admin';
      case 'EMPLOYER':
        return 'Company Representative';
      case 'ENFORCEMENT_OFFICER':
        return 'Enforcement Officer';
      default:
        return '';
    }
  };

  // console.log(userInput);
  const changeDisplayPass = (name) => {
    setShowPassword({ ...showPassword, [name]: !showPassword[name] });
  };
  return (
    <Box style={style}>
      <Box>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Typography variant="h6">{editState ? `${editValue.firstName} ${editValue.lastName}` : 'Add User'}</Typography>
          </Stack>
          { loading && <CircularProgress color="success" sx={circularLoadingStyle} />}
          {
              editState && (
                <Stack
                  spacing={2}
                  direction="row"
                >
                  <Button variant="outlined" sx={{ width: 90 }} onClick={() => setDeleteModal(true)} startIcon={<DeleteRoundedIcon />}>Delete</Button>
                </Stack>
              )
          }
        </div>
      </Box>
      <Box sx={{
        display: 'grid',
        gridAutoColumns: '1fr',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr',
        gap: '6px 12px',
        gridTemplateAreas:
                '. .',
        marginBottom: '16px',
      }}
      >
        <div>
          <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
            <TextField required id="standard-basic" label="First Name" sx={{ width: '100%' }} value={userInput.firstName} name="firstName" onChange={(e) => handleTextFieldChange(e)} />
          </FormControl>
          <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
            <TextField required id="standard-basic" label="Last Name" sx={{ width: '100%' }} value={userInput.lastName} name="lastName" onChange={(e) => handleTextFieldChange(e)} />
          </FormControl>
          <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
            <TextField required id="standard-basic" label="IC Number" sx={{ width: '100%' }} value={userInput.icNumber} name="icNumber" onChange={(e) => handleTextFieldChange(e)} />
          </FormControl>
          <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
            <Autocomplete
              value={valueChangeDropDownList(userInput.role)}
              getOptionLabel={(option) => option.name ?? option}
              onChange={(e, newValue, reason) => {
                handleAutocompleteChange(e, newValue, 'role', reason);
              }}
              options={userTypelist}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  labelId="demo-simple-select-standard-label"
                  label="User Role"
                />
              )}
            />
          </FormControl>
          {
            userInput.role === 'EMPLOYER' ? (
              <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
                <Autocomplete
                  value={userInput.company.name}
                  getOptionLabel={(option) => option.name ?? option}
                  onChange={(e, newValue, reason) => {
                    handleChangeCompanyAutocomplete(e, newValue, 'company', reason);
                  }}
                  options={companyList}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      labelId="demo-simple-select-standard-label"
                      label="Company"
                    />
                  )}
                />
              </FormControl>
            ) : (
              <></>
            )
          }
        </div>
        <div>
          <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
            <TextField required id="standard-basic" label="Email" sx={{ width: '100%' }} disabled={editState ? true : false} name="email" value={userInput.email} onChange={(e) => handleTextFieldChange(e)} />
          </FormControl>
          <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
            <MuiPhoneNumber
              value={userInput.phoneNumber}
              id="outlined-basic"
              defaultCountry="my"
              variant="outlined"
              onChange={(e, country) => handlePhoneNumber(e, 'phoneNumber', country)}
              autoFormat={false}
            />
          </FormControl>
          <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
            <TextField
              requiredid="standard-basic"
              label="Password"
              type={showPassword.password ? 'text' : 'password'}
              value={userInput.password}
              name="password"
              error={errPassword.password}
              helperText={errPassword.password ? userInput.password.length < 6 ? 'Password length must be at least 6 characters' : 'The password confirmation does not match' : '' }
              onChange={(e) => handleTextFieldChange(e)}
              sx={{ width: '100%' }}
            />
            {
                showPassword.password ? (
                  <div style={{
                    margin: '0px 0px 0px 8px', position: 'absolute', right: '16px', top: '16px',
                  }}
                  >
                    <RemoveRedEyeIcon onClick={() => changeDisplayPass('password')} />
                  </div>
                ) : (
                  <div style={{
                    margin: '0px 0px 0px 8px', position: 'absolute', right: '16px', top: '16px',
                  }}
                  >
                    <VisibilityOffIcon onClick={() => changeDisplayPass('password')} />
                  </div>
                )
            }
          </FormControl>
          <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
            <TextField
              required
              id="standard-basic"
              label="Confirm Password"
              value={userInput.confirmPassword}
              onChange={(e) => handleTextFieldChange(e)}
              name="confirmPassword"
              type={showPassword.confirmPassword ? 'text' : 'password'}
              error={errPassword.confirmPassword}
              helperText={errPassword.confirmPassword ? 'The password confirmation does not match' : ''}
              sx={{ width: '100%' }}
            />
            {
                showPassword.confirmPassword ? (
                  <div style={{
                    margin: '0px 0px 0px 8px', position: 'absolute', right: '16px', top: '16px',
                  }}
                  >
                    <RemoveRedEyeIcon onClick={() => changeDisplayPass('confirmPassword')} />
                  </div>
                ) : (
                  <div style={{
                    margin: '0px 0px 0px 8px', position: 'absolute', right: '16px', top: '16px',
                  }}
                  >
                    <VisibilityOffIcon onClick={() => changeDisplayPass('confirmPassword')} />
                  </div>
                )
            }
          </FormControl>
        </div>
      </Box>
      {/* {
          userInput.role === 'FIC_ADMIN' && (
            <Box sx={{ marginTop: '24px', width: '100%' }}>
              <Stack spacing={2} sx={{ width: '100%', marginBottom: '16px' }}>
                <Typography variant="subtitle2">Permission</Typography>
              </Stack>
              <FormControl sx={{
                marginBottom: '8px',
                display: 'grid',
                gridAutoColumns: '1fr',
                gridTemplateColumns: '1fr 1fr',
                gap: '0px 8px',
                gridTemplateAreas: '. .',
                justifyContent: 'center',
              }}
              >
                <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
                  <FormLabel component="legend">Worker Management</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={userInput.privilege ? userInput.privilege.create : false}
                          onChange={(e) => handleWorkerManagement(e)}
                          name="create"
                        />
                )}
                      label="Create"
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={userInput.privilege ? userInput.privilege.read : false}
                          onChange={(e) => handleWorkerManagement(e)}
                          name="read"
                        />
                )}
                      label="Read"
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={userInput.privilege ? userInput.privilege.update : false}
                          onChange={(e) => handleWorkerManagement(e)}
                          name="update"
                        />
                )}
                      label="Update"
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={userInput.privilege ? userInput.privilege.delete : false}
                          onChange={(e) => handleWorkerManagement(e)}
                          name="delete"
                        />
                )}
                      label="Delete"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
                  <FormLabel component="legend">Card Operation</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={userInput.privilege ? userInput.privilege.printCard : false}
                          onChange={(e) => handleCardManagement(e)}
                          name="printCard"
                        />
                      )}
                      label="Print Card"
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={userInput.privilege ? userInput.privilege.writeCard : false}
                          onChange={(e) => handleCardManagement(e)}
                          name="writeCard"
                        />
                      )}
                      label="Update Card"
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={userInput.privilege ? userInput.privilege.verifyCard : false}
                          onChange={(e) => handleCardManagement(e)}
                          name="verifyCard"
                        />
                      )}
                      label="Verify Card"
                    />
                  </FormGroup>
                </FormControl>
              </FormControl>
            </Box>
          )
    } */}
      <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
        <Stack
          spacing={2}
          direction="row"
          sx={{
            marginTop: '16px',
          }}
        >
          <Button variant="outlined" sx={{ width: 90 }} onClick={() => removeState()}>Cancel</Button>
          <Button variant="contained" sx={{ width: 90 }} disabled={!btnDisabled} onClick={() => handleSaveButton()}>Save</Button>
        </Stack>
      </Box>
      <DeleteModal
        handleDelete={deleteButton}
        modalDel={deleteModal}
        handleDeleteClose={handleDeleteClose}
        label="Are you sure you want to delete?"
        data={editValue && editValue._id}
      />
    </Box>
  );
};

FormUser.propTypes = {
  style: PropTypes.object,
  editState: PropTypes.bool,
  editValue: PropTypes.object,
  setEditValue: PropTypes.func,
  removeAllState: PropTypes.func,
  setErrMsg: PropTypes.func,
  setOpen: PropTypes.func,
  setVariant: PropTypes.func,
  apiCalled: PropTypes.func,
  apiCompanyCalled: PropTypes.func,
  userPrivi: PropTypes.array,
  companyList: PropTypes.array,
};

export default FormUser;
