/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import React, { useState, useEffect, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
} from '@mui/material';
// components
import FormCompany from '../components/dashboard/company/FormCompany';
import Page from '../components/Page';
// import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import { TableListHead, TableListToolbar } from '../sections/@dashboard/user';
import { APIFetchUser, APIFetchCompany } from '../utils/apiCalled';
import MenuPopover from '../components/MenuPopover';
import TagsInput from '../components/TagsInput';
import SnackBar from '../components/SnackBar';
import ModalParent from '../components/ModalParent';
import { tableCircularLoadingStyle, rowsLimit } from '../utils/config';
import ListPagination from '../components/ListPagination';
// ----------------------------------------------------------------------

const styleDesktop = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75vw',
  backgroundColor: 'white',
  boxShadow: 24,
  padding: '32px',
  borderRadius: '16px',
};

const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: '16px',
  maxHeight: '90%',
  width: '80vw',
  overflow: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};
const company = [{
  _id: 1,
  companyName: 'Test Company',
  companyAddress: 'Test address',
  companyIndustry: 'Oil',
  representatives: [{
    _id: 1,
    fullName: 'John Doe',
    nricNumber: '102345678912345678',
    phoneNo: '123456789123456789',
  },
  {
    _id: 2,
    fullName: 'John Doe',
    nricNumber: '102345678912345678',
    phoneNo: '123456789123456789',
  }, {
    _id: 3,
    fullName: 'John Doe',
    nricNumber: '102345678912345678',
    phoneNo: '123456789123456789',
  }, {
    _id: 4,
    fullName: 'John Doe',
    nricNumber: '102345678912345678',
    phoneNo: '123456789123456789',
  }, {
    _id: 5,
    fullName: 'John Doe',
    nricNumber: '102345678912345678',
    phoneNo: '123456789123456789',
  }, {
    _id: 6,
    fullName: 'John Doe',
    nricNumber: '102345678912345678',
    phoneNo: '123456789123456789',
  }, {
    _id: 7,
    fullName: 'John Doe',
    nricNumber: '102345678912345678',
    phoneNo: '123456789123456789',
  }, {
    _id: 8,
    fullName: 'John Doe',
    nricNumber: '102345678912345678',
    phoneNo: '123456789123456789',
  }],
},
{
  _id: 2,
  companyName: 'Test 2',
  companyAddress: 'Test 2',
  companyIndustry: 'Oil',
}, {
  _id: 3,
  companyName: 'Test Company',
  companyAddress: 'Test address',
  companyIndustry: 'Oil',
}, {
  _id: 4,
  companyName: 'Test Company',
  companyAddress: 'Test address',
  companyIndustry: 'Oil',
}, {
  _id: 5,
  companyName: 'Test Company',
  companyAddress: 'Test address',
  companyIndustry: 'Oil',
}, {
  _id: 6,
  companyName: 'Test Company',
  companyAddress: 'Test address',
  companyIndustry: 'Oil',
}, {
  _id: 7,
  companyName: 'Test Company',
  companyAddress: 'Test address',
  companyIndustry: 'Oil',
}, {
  _id: 8,
  companyName: 'Test Company',
  companyAddress: 'Test address',
  companyIndustry: 'Oil',
}, {
  _id: 9,
  companyName: 'Test Company',
  companyAddress: 'Test address',
  companyIndustry: 'Oil',
}, {
  _id: 10,
  companyName: 'Test Company',
  companyAddress: 'Test address',
  companyIndustry: 'Oil',
}, {
  _id: 11,
  companyName: 'Test Company',
  companyAddress: 'Test address',
  companyIndustry: 'Oil',
}];

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'industry', label: 'Industry', alignRight: false },
];

export default function Company() {
  const [page, setPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const token = Cookies.get('token');
  const role = Cookies.get('role');

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);
  const [searchTimeout, setSearchTimeout] = useState(null); // this for filterTimeout
  const [orderBy, setOrderBy] = useState('companyName');
  const [callAPI, setcallAPI] = useState(false); // indicator to call api for pagination
  const [filterName, setFilterName] = useState('');
  const [companyData, setCompanyData] = useState([]); // list of the userData take from fetchWorker API
  const [loading, setLoading] = useState(false); // all the loading state (when called the API)
  const [apiCalledComponent, setApiCalledComponent] = useState(false); // this to call useEffect API if on different component.
  const [modalClick, setModalClick] = useState(false);
  const [openFilterPopUp, setOpenFilterPopUp] = useState(null); // filter popup
  const [editValue, setEditValue] = useState(null);
  const [editState, setEditState] = useState(false);
  const [variant, setVariant] = useState('');
  const [open, setOpen] = useState(false); // open snackbar
  const [errMsg, setErrMsg] = useState(''); // error message snackbar

  const [dupFilter, setDupFilter] = useState({
    name: [],
    industry: [],
  });

  const [getFilter, setGetFilter] = useState({
    name: null,
    industry: null,
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const removeAllState = () => {
    setModalClick(false);
    setEditValue(null);
    setEditState(false);
  };

  const handleOpen = () => {
    setModalClick(true);
  };

  const handleClickEdit = (value) => {
    setEditValue(value);
    setEditState(true);
    setModalClick(true);
  };

  const apiCompanyCalled = async (params, nameFilter) => {
    try {
      const result = await APIFetchCompany(params, nameFilter, rowsPerPage, page, setLoading, token);
      if (result.data && result.data.status === 200) {
        const { data, totalPage } = result.data;
        setCompanyData(data);
        setPageTotal(totalPage);
        setLoading(false);
      } else {
        setLoading(false);
        setOpen(true);
        setVariant('error');
        setErrMsg(`Error! ${result.response.data.message}`);
      }
    } catch (err) {
      setLoading(false);
      setOpen(true);
      setVariant('error');
      setErrMsg(`API Function Error, ${err}`);
    }
  };

  useEffect(() => {
    if (token) {
      apiCompanyCalled(null, null);
    }
  }, []);

  // this to call when click limit or pagination
  useEffect(() => {
    if (callAPI) {
      apiCompanyCalled(getFilter, filterName);
      setcallAPI(false);
    }
  }, [callAPI]);

  // this to call when to call api to clear the filter
  useEffect(() => {
    if (apiCalledComponent) {
      apiCompanyCalled(getFilter, filterName);
      setApiCalledComponent(false);
    }
  }, [apiCalledComponent]);

  // this function to close the filter popup
  const closePopup = () => {
    setOpenFilterPopUp(null);
  };

  // this to call clear filter button
  const clearFilterButton = () => {
    setGetFilter({
      name: null,
      industry: null,
    });
    setDupFilter({
      name: [],
      industry: [],
    });
    setApiCalledComponent(true); // this state activate apiCalled.
  };

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    // this to call api and setTimeout to 0.2 sec
    setSearchTimeout(
      setTimeout(() => {
        apiCompanyCalled(getFilter, filterName);
        setPage(1);
      }, 200),
    );
    return () => clearTimeout(searchTimeout); // this also to clear time out
  }, [filterName]);

  // This function for filter button
  const filterButton = () => {
    apiCompanyCalled(getFilter, filterName);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleSelection = (e) => {
    setRowsPerPage(e.target.value);
    setPage(1);
    setcallAPI(true);
  };

  const handlePagination = (e, pageNo) => {
    setPage(pageNo);
    setcallAPI(true);
  };

  return (
    <Page title="Company">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Company Management
          </Typography>
          {loading && <CircularProgress color="success" sx={tableCircularLoadingStyle} />}
          <Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => handleOpen()}>
            New Company
          </Button>
        </Stack>
        <SnackBar alert={variant} handleClose={() => setOpen(false)} open={open} errorMsg={errMsg} />
        <Card>
          <TableListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} popupfilter={setOpenFilterPopUp} labelName="Search Company..." displayFilter />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={companyData.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                />
                <MenuPopover open={Boolean(openFilterPopUp)} anchorEl={openFilterPopUp} onClose={closePopup} styleWidth={500}>
                  <Box>
                    <Typography variant="subtitle1" sx={{ paddingTop: 1, paddingBottom: 2, paddingLeft: 1 }}>Filter By</Typography>
                    <TagsInput fullWidth variant="outlined" id="industry" name="industry" placeholder="add tags" label="industry" dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="industry" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <Button variant="outlined" sx={{ width: 110, marginRight: '8px' }} component={RouterLink} to="#" onClick={() => clearFilterButton()}>
                      Clear Filter
                    </Button>
                    <Button variant="contained" sx={{ width: 110 }} component={RouterLink} to="#" onClick={() => filterButton()}>
                      Filter
                    </Button>
                  </Box>
                </MenuPopover>
                <TableBody>
                  {companyData.map((row) => {
                    const {
                      _id, name, address, industry,
                    } = row;
                    const isItemSelected = selected.indexOf(_id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell align="left" sx={{ cursor: 'pointer' }} onClick={() => handleClickEdit(row)}>{name}</TableCell>
                        <TableCell align="left" sx={{ cursor: 'pointer' }} onClick={() => handleClickEdit(row)}>{address}</TableCell>
                        <TableCell align="left" sx={{ cursor: 'pointer' }} onClick={() => handleClickEdit(row)}>{industry}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <ModalParent modalClick={modalClick} removeAllState={removeAllState}>
              <FormCompany
                style={styleDesktop}
                editState={editState}
                setEditState={setEditState}
                setEditValue={setEditValue}
                editValue={editValue}
                removeAllState={removeAllState}
                setOpen={setOpen}
                setErrMsg={setErrMsg}
                setVariant={setVariant}
                apiCalled={apiCompanyCalled}
              />
            </ModalParent>
          </Scrollbar>
          <Box sx={{ margin: '16px 8px' }}>
            <ListPagination count={pageTotal} handlePagination={handlePagination} limitValue={rowsPerPage} listofLimit={rowsLimit} handleSelection={handleSelection} />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
