import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '16px',
  p: 4,
};

export default function DeleteModal({
  handleDeleteClose, modalDel, handleDelete, label, data,
}) {
  return (
    <Modal
      open={modalDel}
      onClose={handleDeleteClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {label}
          <Stack spacing={2} direction="row" sx={{ marginTop: '16px' }}>
            <Button variant="outlined" sx={{ width: 90 }} onClick={() => handleDeleteClose()}>No</Button>
            <Button variant="contained" sx={{ width: 90 }} onClick={() => handleDelete(data)}>Yes</Button>
          </Stack>
        </Typography>
      </Box>
    </Modal>
  );
}

DeleteModal.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  modalDel: PropTypes.bool.isRequired,
  handleDeleteClose: PropTypes.func.isRequired,
  label: PropTypes.string,
  data: PropTypes.shape({}).isRequired,
};

DeleteModal.defaultProps = {
  label: 'Are you sure you want to delete?',
};
