/* eslint-disable react/forbid-prop-types */
import React from 'react';
import {
  Typography,
  Pagination,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import PropTypes from 'prop-types';

const ListPagination = ({
  count, handlePagination, handleSelection, limitValue, listofLimit, page,
}) => (
  <FormControl
    fullWidth
    sx={{
      display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',
    }}
  >
    <Typography sx={{ marginRight: '16px', display: 'flex', alignItems: 'center' }}>Rows per page : </Typography>
    <Select
      id="demo-simple-select"
      value={limitValue}
      onChange={handleSelection}
      size="small"
    >
      {
        listofLimit.map((x) => (
          <MenuItem value={x.value}>{x.value}</MenuItem>
        ))
      }
    </Select>
    <Pagination sx={{ display: 'flex', alignItems: 'center' }} page={page} onChange={(e, page) => handlePagination(e, page)} count={count} color="primary" />
  </FormControl>
);

export default ListPagination;

ListPagination.propTypes = {
  count: PropTypes.number,
  handlePagination: PropTypes.bool,
  handleSelection: PropTypes.object,
  limitValue: PropTypes.func,
  listofLimit: PropTypes.func,
};
