/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Modal,
  TextField,
  Typography,
  Button,
  Stack,
  Card,
  Icon,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  width: '100vw',
  height: '100vh',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  // pt: 2,
  // px: 4,
};

const HotlistModel = ({
  hotListed, setViewOpen, viewOpen, isHotlist, widths, heights, setWorkerInput, workerInput,
}) => {
  const handleClose = () => {
    setViewOpen(false);
  };
  return (
    <Modal
      open={viewOpen}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box style={{ ...style }}>
        <Card sx={{
          width: widths, height: heights, padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center',
        }}
        >
          <Icon
            sx={{
              color: '#939598', fontWeight: 700, cursor: 'pointer', position: 'absolute', top: 16, right: 16,
            }}
            onClick={handleClose}
          >
            <CloseRoundedIcon />
          </Icon>
          <Typography
            sx={{
              display: 'flex', width: '100%', justifyContent: 'center', marginBottom: '8px',
            }}
            variant="subtitle1"
          >
            Hotlist
          </Typography>
          <TextField multiline rows={3} fullWidth id="standard-basic" label="Hotlist Reason" sx={{ width: '100%', marginTop: '8px' }} value={workerInput.hotlistReason} onChange={(e) => setWorkerInput({ ...workerInput, hotlistReason: e.target.value })} />
          <Stack
            spacing={2}
            direction="row"
            sx={{ marginTop: '24px' }}
          >
            <Button variant="contained" sx={{ width: 200 }} onClick={() => hotListed(!isHotlist, workerInput.hotlistReason)}>
              {isHotlist ? 'Remove from Hotlist' : 'Add to Hotlist'}
            </Button>
            <Button variant="outlined" sx={{ width: 210 }} onClick={() => hotListed(!isHotlist, workerInput.hotlistReason, 'updateHotlistReason')}>
              Update Hotlist Reason
            </Button>
          </Stack>
        </Card>
      </Box>
    </Modal>
  );
};

export default HotlistModel;

HotlistModel.propTypes = {
  setViewOpen: PropTypes.func,
  viewOpen: PropTypes.bool,
  hotListed: PropTypes.func,
  isHotlist: PropTypes.bool,
  widths: PropTypes.string,
  heights: PropTypes.string,
  setWorkerInput: PropTypes.func,
  workerInput: PropTypes.object,
};
