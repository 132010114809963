import React from 'react';
// import permissionDenied from '../img/11132.jpg';

const cssStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  fontSize: '24px',
  fontWeight: 'bold',
};
export default function NoAccess() {
  return (
    <div style={cssStyle}>
      {/* <img src={permissionDenied} alt="noAccess" /> */}
      Permission Denied
    </div>
  );
}
