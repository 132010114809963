// to check mandatory field
import moment from 'moment';

export const AllMandatoryFilled = (requiredField, input) => requiredField.every((a) => {
  if (Object.keys(input).includes(a) && input[a]) {
    if (Array.isArray(input[a])) {
      return input[a].length > 0;
    }
    return true;
  }
  return false;
});

// requiredField
export const requiredField = {
  worker: ['passportNumber'],
  user: ['firstName', 'lastName', 'icNumber', 'email', 'password', 'role'],
  card: ['issueReason', 'validFrom', 'validTo'],
  company: ['name'],
};

// this is for FormWorker.js to display list of genderList
export const genderList = [
  { id: 100, name: 'Male', value: 'male' },
  { id: 200, name: 'Female', value: 'female' },
];

// this is for FormWorker.js to display list of status
export const statusList = [
  { id: 100, name: 'active', value: 'active' },
  { id: 200, name: 'expired', value: 'expired' },
];

export const circularLoadingStyle = {
  position: 'absolute',
  top: '45%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export const tableCircularLoadingStyle = {
  position: 'absolute',
  top: '40%',
  left: '55%',
  transform: 'translate(-50%, -55%)',
  zIndex: 1,
};

export const workerIssueReasonList = [
  { id: 100, name: 'First Card', value: 'first_card' },
  { id: 200, name: 'Lost Card', value: 'lost_card' },
  { id: 300, name: 'Damage Card', value: 'damaged_card' },
  { id: 499, name: 'Previous Card Expired', value: 'previous_card_expired' },
];

export const userTypelist = [
  { id: 100, name: 'FIC Superadmin', value: 'FIC_SUPERADMIN' },
  { id: 200, name: 'FIC Admin', value: 'FIC_ADMIN' },
  { id: 300, name: 'Company Representative', value: 'EMPLOYER' },
  { id: 400, name: 'Enforcement Officer', value: 'ENFORCEMENT_OFFICER' },
];

export const rowsLimit = [{ id: '102', value: 10 }, { id: '103', value: 15 }, { id: '104', value: 25 }, { id: '105', value: 50 }];

export const formatDate = (date) => {
  const convertMoment = moment(date).format('DD/MM/YYYY');
  return convertMoment;
};
