/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FileBase64 from 'react-file-base64';
import {
  TextField,
  Box,
  Typography,
  Avatar,
  FormControl,
  Stack,
  Button,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import Cookies from 'js-cookie';
import moment from 'moment';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import WhatshotRoundedIcon from '@mui/icons-material/WhatshotRounded';
import TableRowsIcon from '@mui/icons-material/TableRows';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import MuiPhoneNumber from 'material-ui-phone-number';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  APICreateWorker,
  APIUpdateWorker,
  APIArchivedWorker,
} from '../../../utils/apiCalled';
import { APIGetCountry } from '../../../utils/countryAPICalled';
import { countryListAllIsoData } from '../../../listOfCountry';
import ModalCardsWorker from './ModalCardsWorker';
import ImageView from '../../ImageView';
import {
  AllMandatoryFilled,
  requiredField,
  genderList,
  statusList,
  circularLoadingStyle,
  workerIssueReasonList,
} from '../../../utils/config';
import DeleteModal from '../../DeleteModal';
import PrintJob from './PrintJob';
import HotlistModel from './HotlistModel';
import CardExpiryModal from './CardExpiryModal';

const cssHotlisted = {
  backgroundColor: 'red',
  borderRadius: '5px',
  padding: 6,
  color: 'white',
  fontSize: '11px',
  fontWeight: 'bold',
};

const FormWorker = ({
  style, editState, setEditValue, setcallAPI, editValue, removeAllState, setOpen, setErrMsg, setVariant, apiCalled, workerPrivi, setDataSet, dataSet, sortingCardData, companyList, setCardDisplay, cardDisplay,
}) => {
  // const userType = Cookies.get('userType');
  // console.log('editState', editState);
  const token = Cookies.get('token');
  const role = Cookies.get('role');

  const country = (value) => {
    const filterCountry = countryListAllIsoData.filter((c) => c.code3 === value);
    if (filterCountry.length > 0) {
      return filterCountry[0].name;
    }
    return '';

    // return filterCountry[0].name;
  };

  const getFullName = (value) => {
    const fields = [];

    if (value.firstName) {
      fields.push(value.firstName);
    }

    if (value.middleName) {
      fields.push(value.middleName);
    }

    if (value.lastName) {
      fields.push(value.lastName);
    }

    if (fields.length > 0) {
      return fields.join(' ');
    }
    return '-';
  };

  const companyName = (value) => {
    const filterCompany = companyList.filter((c) => c.id === value);
    if (filterCompany.length > 0) {
      return filterCompany[0].name;
    }
    return '';
  };

  const displayCardStatus = (value) => {
    switch (value) {
      case 'printed':
        return 'Printed';
      case 'reprinted':
        return 'Reprinted';
      case 'written':
        return 'Data Verified & Uploaded';
      case 'rewritten':
        return 'Reset Data & Data Reuploaded';
      case 'expired':
        return 'Expired';
      case 'fic_verified':
        return 'FIC Verified';
      case 'employer_verified':
        return 'Employer Verified';
      case 'activated':
        return 'Card Activated';
      case 'inactive':
        return 'Inactive';
      default:
        return value;
    }
  };

  // console.log(editValue);

  const [workerInput, setWorkerInput] = useState({
    photoURL: editState ? editValue.photoURL : null,
    visaImage: editState ? editValue.visaImageURL : null,
    myTravelImage: editState ? editValue.myTravelImageURL : null,
    licenseImage: editState ? editValue.licenseImageURL : null,
    company: editState ? { name: companyName(editValue.company), companyOid: editValue.company } : { name: null, companyOid: null },
    firstName: editState ? editValue.firstName : null,
    middleName: editState ? editValue.middleName : null,
    lastName: editState ? editValue.lastName : null,
    phone: editState ? editValue.phone : null,
    email: editState ? editValue.email : null,
    gender: editState ? editValue.gender : null,
    birthDate: editState ? editValue.birthDate : null,
    nationality: editState ? { name: country(editValue.nationality), code3: editValue.nationality } : {
      code: '', code3: '', name: '', number: '',
    },
    passportNumber: editState ? editValue.passportNumber : null,
    passportExpiry: editState ? editValue.passportExpiry : null,
    status: editState ? editValue.status : null,
    card: editState ? editValue.card : null,
    isHotlisted: editState ? editValue.isHotlisted : null,
    hotlistReason: editState ? editValue.hotlistReason ? editValue.hotlistReason : null : null,
    validTo: editState ? editValue.card.validTo : null,
  });
  // console.log(workerInput);
  const [countryList, setCountryList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hotlistModel, setHotlistModel] = useState(false);
  const [cardExpiryModel, setCardExpiryModel] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [viewOpen, setViewOpen] = useState(false);
  const [imgDisplay, setImgDisplay] = useState(null);
  const [cardModal, setCardModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [cardPrint, setCardPrint] = useState(false);
  const [borderDisplay, setBorderDisplay] = useState(true); // this to display border of the card and remove when print
  const [cardIdDisplay, setCardIdDisplay] = useState(null);
  const [cardExpiryDate, setCardExpiryDate] = useState(null);
  const getCountry = async () => {
    try {
      const result = await APIGetCountry(setLoading);
      if (result.status === 200) {
        setLoading(false);
        setCountryList(result.data);
      } else {
        setLoading(false);
        setOpen(true);
        setErrMsg(`Error! ${result.response.data.message}`);
      }
    } catch (err) {
      setLoading(false);
      setOpen(true);
      setErrMsg(`Error! : ${err} Please try again`);
    }
  };

  const fetchData = () => {
    if (editValue) {
      setLoading(true);
      setWorkerInput({
        company: { name: companyName(editValue.company), companyOid: editValue.company },
        firstName: editValue.firstName,
        middleName: editValue.middleName,
        lastName: editValue.lastName,
        phone: editValue.phone,
        email: editValue.email,
        gender: editValue.gender,
        birthDate: editValue.birthDate,
        nationality: { name: country(editValue.nationality), code3: editValue.nationality },
        passportNumber: editValue.passportNumber,
        passportExpiry: editValue.passportExpiry,
        status: editValue.status,
        photoURL: editValue.photoURL,
        licenseImage: editValue.licenseImageURL,
        myTravelImage: editValue.myTravelImageURL,
        visaImage: editValue.visaImageURL,
        isHotlisted: editValue.isHotlisted,
        hotlistReason: editValue.hotlistReason ? editValue.hotlistReason : null,
        card: editValue.card ? editValue.card : null,
        validTo: editState ? editValue.card.validTo : null,
      });

      setLoading(false);
    }
  };

  const uploadImage = async (image, name) => {
    if (image[0].type.includes('image')) {
      const { base64 } = image[0];
      setWorkerInput({ ...workerInput, [name]: base64 });
    } else {
      setWorkerInput({ ...workerInput, [name]: '' });
    }
  };

  const openImageViewer = (value, imgLocation) => {
    setViewOpen(true);
    setImgDisplay(imgLocation);
  };

  const openCardListViewer = () => {
    setCardModal(true);
  };

  const checkValidationWorker = () => {
    const checkedValidation = AllMandatoryFilled(requiredField.worker, workerInput);
    // console.log('checkedValidation :', checkedValidation);
    return checkedValidation;
  };

  const handleChangeAutocomplete = (e, value, name, reason) => {
    if (name === 'nationality') {
      if (reason === 'clear') {
        setWorkerInput({ ...workerInput, [name]: { name: '', code3: null } });
      } else {
        setWorkerInput({ ...workerInput, [name]: value });
      }
    } else if (reason === 'clear') {
      setWorkerInput({ ...workerInput, [name]: null });
    } else {
      setWorkerInput({ ...workerInput, [name]: value.name });
    }
  };

  const handleChangeCompanyAutocomplete = (e, value, name, reason) => {
    if (reason === 'clear') {
      setWorkerInput({ ...workerInput, [name]: { name: '', companyOid: '' } });
    } else {
      setWorkerInput({ ...workerInput, [name]: { name: value.name, companyOid: value.id } });
    }
  };

  const handleOnChanged = (value, name) => {
    if (value !== '') {
      setWorkerInput({ ...workerInput, [name]: value });
    } else {
      setWorkerInput({ ...workerInput, [name]: null });
    }
  };

  const handleChangePhoneNumber = (e, value, name) => {
    setWorkerInput({ ...workerInput, [name]: e });
  };

  const handleChangeCard = (e, value, name, reason) => {
    if (reason === 'clear') {
      setWorkerInput({ ...workerInput, card: { [name]: '' } });
    } else {
      setWorkerInput({ ...workerInput, card: { ...workerInput.card, [name]: value.value } });
    }
  };

  const removeState = () => {
    removeAllState();
    setWorkerInput({
      photoURL: null,
      company: null,
      firstName: null,
      middleName: null,
      lastName: null,
      phone: null,
      email: null,
      gender: null,
      birthDate: null,
      nationality: null,
      passportNumber: null,
      passportExpiry: null,
      status: null,
    });
    setImgDisplay(null);
    setHotlistModel(false);
    setCardExpiryModel(false);
  };

  const apiAddEditCalled = async (value, id) => {
    try {
      const result = editState ? await APIUpdateWorker(value, id, setLoading, token) : await APICreateWorker(value, setLoading, token);
      // console.log('result', result);
      if (result.data && result.data.status === 200) {
        Cookies.set('userType', JSON.stringify(result.data.user.userType));
        setLoading(false);
        setOpen(true);
        setErrMsg('Thank you! Your submission has been sent!');
        setVariant('success');
        apiCalled();
        removeAllState();
      } else {
        setLoading(false);
        setOpen(true);
        setErrMsg(`Error! ${result.response.data.message}`);
        setVariant('error');
      }
    } catch (err) {
      setLoading(false);
      setOpen(true);
      setErrMsg(`Error!: ${err}`);
      setVariant('error');
    }
  };

  const arrangeInput = (stateValue, apiValue) => {
    const input = {};
    if (stateValue.firstName !== apiValue.firstName) {
      input.firstName = stateValue.firstName;
    }
    if (stateValue.middleName !== apiValue.middleName) {
      input.middleName = stateValue.middleName;
    }
    if (stateValue.company.companyOid !== apiValue.company) {
      input.companyOid = stateValue.company.companyOid;
    }
    if (stateValue.lastName !== apiValue.lastName) {
      input.lastName = stateValue.lastName;
    }
    if (stateValue.phone !== apiValue.phone) {
      input.phone = stateValue.phone;
    }
    if (stateValue.email !== apiValue.email) {
      input.email = stateValue.email;
    }
    if (stateValue.gender !== apiValue.gender) {
      input.gender = stateValue.gender;
    }
    if (stateValue.birthDate !== apiValue.birthDate) {
      input.birthDate = stateValue.birthDate;
    }
    if (stateValue.nationality.code3 !== apiValue.nationality) {
      input.nationality = stateValue.nationality.code3;
    }
    if (stateValue.passportNumber !== apiValue.passportNumber) {
      input.passportNumber = stateValue.passportNumber;
    }
    if (stateValue.passportExpiry !== apiValue.passportExpiry) {
      input.passportExpiry = stateValue.passportExpiry;
    }
    if (stateValue.status !== apiValue.status) {
      input.status = stateValue.status;
    }
    if (stateValue.photoURL !== apiValue.photoURL) {
      input.photo = stateValue.photoURL;
    }
    if (apiValue.card) {
      if (stateValue.validTo) {
        const stateDate = new Date(stateValue.validTo).toDateString();
        const apiDate = new Date(apiValue.card.validTo).toDateString();
        if (stateDate !== apiDate) {
          const cardValidity = { validTo: workerInput.validTo };
          input.card = cardValidity;
        }
      }
    }

    return input;
  };

  // console.log('editValue', editValue);
  const reducerWorker = () => {
    const input = {};
    if (workerInput.firstName) {
      input.firstName = workerInput.firstName;
    }
    if (workerInput.middleName) {
      input.middleName = workerInput.middleName;
    }
    if (workerInput.company) {
      if (workerInput.company.companyOid) {
        input.companyOid = workerInput.company.companyOid;
      }
    }
    if (workerInput.lastName) {
      input.lastName = workerInput.lastName;
    }
    if (workerInput.phone) {
      input.phone = workerInput.phone;
    }
    if (workerInput.email) {
      input.email = workerInput.email;
    }
    if (workerInput.gender) {
      input.gender = workerInput.gender;
    }
    if (workerInput.birthDate) {
      input.birthDate = workerInput.birthDate;
    }
    if (workerInput.nationality.code3) {
      if (Object.keys(workerInput.nationality).length > 0) {
        input.nationality = workerInput.nationality.code3;
      }
    }
    if (workerInput.passportNumber) {
      input.passportNumber = workerInput.passportNumber;
    }
    if (workerInput.passportExpiry) {
      input.passportExpiry = workerInput.passportExpiry;
    }
    if (workerInput.status) {
      input.status = workerInput.status;
    }
    if (workerInput.photoURL) {
      input.photo = workerInput.photoURL;
    }
    if (workerInput.validTo) {
      const cardValidity = { validTo: workerInput.validTo };
      input.card = cardValidity;
    }
    return input;
  };

  const handleSaveButton = (value) => {
    if (editState) {
      const workerValue = arrangeInput(workerInput, editValue);
      const { _id } = editValue;
      // console.log(workerValue, _id);
      apiAddEditCalled(workerValue, _id);
    } else {
      const workerValue = reducerWorker();
      // console.log(workerValue);
      apiAddEditCalled(workerValue, null);
    }
  };

  // this to check if there is changes on details page. this checking for btn disabled feature.
  const checkDetailChanges = () => {
    const input = {};

    if (workerInput.firstName !== editValue.firstName) {
      input.firstName = workerInput.firstName;
    }
    if (workerInput.middleName !== editValue.middleName) {
      input.middleName = workerInput.middleName;
    }
    if (workerInput.company.companyOid !== editValue.company) {
      input.companyOid = workerInput.companyOid;
    }
    if (workerInput.lastName !== editValue.lastName) {
      input.lastName = workerInput.lastName;
    }
    if (workerInput.phone !== editValue.phone) {
      input.phone = workerInput.phone;
    }
    if (workerInput.email !== editValue.email) {
      input.email = workerInput.email;
    }
    if (workerInput.gender !== editValue.gender) {
      input.gender = workerInput.gender;
    }
    if (workerInput.birthDate !== editValue.birthDate) {
      input.birthDate = workerInput.birthDate;
    }
    if (workerInput.nationality.code3 !== editValue.nationality) {
      input.nationality = workerInput.nationality;
    }
    if (workerInput.passportNumber !== editValue.passportNumber) {
      input.passportNumber = workerInput.passportNumber;
    }
    if (workerInput.passportExpiry !== editValue.passportExpiry) {
      input.passportExpiry = workerInput.passportExpiry;
    }
    if (workerInput.status !== editValue.status) {
      input.status = workerInput.status;
    }
    if (workerInput.photoURL !== editValue.photoURL) {
      input.photo = workerInput.photoURL;
    }
    if (editValue.card) {
      if (workerInput.validTo) {
        const stateDate = new Date(workerInput.validTo).toDateString();
        const apiDate = editValue.card.validTo ? new Date(editValue.card.validTo).toDateString() : null;
        if (stateDate !== apiDate) {
          const cardValidity = { validTo: workerInput.validTo };
          input.card = cardValidity;
        }
      }
    }
    // console.log('checking :', input);
    const checking = Object.keys(input).length > 0;
    return checking;
  };

  const checkEmailValidation = () => {
    const emRegex = new RegExp(/^(([^<>()[\].,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const validation = workerInput && workerInput.email ? emRegex.test(workerInput.email) : false;
    // console.log('validation :', validation);
    return validation;
  };

  const cardChecking = () => {
    const checkedValidation = AllMandatoryFilled(requiredField.card, workerInput.card);
    return checkedValidation;
  };

  const deleteButton = async (workerId) => {
    try {
      const result = await APIArchivedWorker(workerId, setLoading, token);
      if (result.data && result.data.status === 200) {
        Cookies.set('role', result.data.user.role);
        setLoading(false);
        setOpen(true);
        setErrMsg('Thank you! Succesfully delete the worker');
        setVariant('success');
        apiCalled();
        removeAllState();
      } else {
        setLoading(false);
        setOpen(true);
        setErrMsg(`Error! ${result.response.data.message}`);
        setVariant('error');
      }
    } catch (err) {
      setLoading(false);
      setOpen(true);
      setErrMsg(`Error!: ${err}`);
      setVariant('error');
    }
  };

  const handlePrintCardModal = () => {
    // setCardExpiryModel(true);
    // setCardExpiryDate(null);
    setCardPrint(true);
  };

  const handleSaveBtnDisabled = () => {
    let valueBool;
    // const valueBool = role === 'FIC_ADMIN' ? workerPrivi.update === true : false;
    if (role === 'FIC_SUPERADMIN') {
      valueBool = true;
    } else if (role === 'FIC_ADMIN') {
      valueBool = workerPrivi.update === true;
    }
    // console.log('btnDisabled: ', valueBool);
    return valueBool;
  };

  useEffect(() => {
    handleSaveBtnDisabled();
  }, []);

  const handleDeleteClose = () => {
    setDeleteModal(false);
  };

  useEffect(() => {
    if (editState) {
      if (workerInput.email) {
        if (checkValidationWorker() && checkDetailChanges() && checkEmailValidation() && handleSaveBtnDisabled()) {
          setBtnDisabled(true);
        } else {
          setBtnDisabled(false);
        }
      } else if (checkValidationWorker() && checkDetailChanges() && handleSaveBtnDisabled()) {
        setBtnDisabled(true);
      } else {
        setBtnDisabled(false);
      }
    } else if (checkValidationWorker()) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [workerInput]);

  useEffect(() => {
    // setLoading(true);
    fetchData();
  }, [editValue]);

  useEffect(() => {
    getCountry();
  }, []);

  const issueReasonReducer = (value) => {
    switch (value) {
      case 'first_card':
        return 'First Card';
      case 'lost_card':
        return 'Lost Card';
      case 'damaged_card':
        return 'Damaged Card';
      case 'previous_card_expired':
        return 'Previous Card Expired';
      default:
        return '';
    }
  };

  const handleDeletePrintCardClose = () => {
    setCardPrint(false);
  };

  // hotlisted button
  const hotListed = (value, reasonHotlisted, buttonIndicator) => {
    // const
    const { _id } = editValue;
    if (buttonIndicator === 'updateHotlistReason') {
      const hotlistReason = { hotlistReason: reasonHotlisted };
      apiAddEditCalled(hotlistReason, _id);
    } else {
      // console.log('go here if relateed to remove or add hotlisted');
      const hotlistReason = value === false ? { hotlistReason: '', isHotlisted: value } : { hotlistReason: reasonHotlisted, isHotlisted: value };
      // console.log('ishotlisted', hotlistReason);
      apiAddEditCalled(hotlistReason, _id);
    }
  };

  return (
    <Box sx={style}>
      <Box>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
          <Stack spacing={2} direction="row" alignItems="center" sx={{ width: '100%' }}>
            <Typography variant="h4">{editState ? getFullName(editValue) : 'Add Worker'}</Typography>
            {workerInput.isHotlisted && (
              <div style={cssHotlisted}>Hotlisted</div>
            )}
          </Stack>
          {loading && <CircularProgress color="success" sx={circularLoadingStyle} />}
          {
            editState && (
              <Stack
                spacing={2}
                direction="row"
              >
                <Button variant="outlined" sx={{ width: 200, marginRight: '16px' }} disabled={role === 'FIC_ADMIN' ? (!workerPrivi.update) : (false)} onClick={() => setHotlistModel(true)} startIcon={<WhatshotRoundedIcon />}>
                  Hotlist
                </Button>
              </Stack>
            )
          }
          {
            editState && (
              <Stack
                spacing={2}
                direction="row"
              >
                <Button variant="outlined" sx={{ width: 90 }} disabled={role === 'FIC_ADMIN' ? (!workerPrivi.delete) : (false)} onClick={() => setDeleteModal(true)} startIcon={<DeleteRoundedIcon />}>Delete</Button>
              </Stack>
            )
          }
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          {/* <div style={workerPrivi.update === true ? { marginBottom: '12px' } : { marginBottom: '0px' }}> */}
          <div style={role === 'FIC_SUPERADMIN' ? {
            marginBottom: '12px', display: 'flex', flexDirection: 'column', justifyContent: 'center',
          } : {
            marginBottom: '0px', display: 'flex', flexDirection: 'column', justifyContent: 'center',
          }}
          >
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              {
                workerInput.photoURL ? (
                  <Avatar
                    alt="passportImageURL"
                    src={workerInput.photoURL}
                    variant="rounded"
                    sx={{
                      height: 160,
                      width: 150,
                      borderRadius: '8px',
                      cursor: 'pointer',
                      '& .MuiAvatar-img': {
                        objectFit: 'contain'
                      }
                    }}
                    onClick={() => openImageViewer('desktop', workerInput.photoURL)}
                  />
                ) : (
                  <div style={{
                    height: '188px',
                    width: '150px',
                    borderRadius: '8px',
                    borderStyle: 'dashed',
                    borderWidth: '1px',
                    borderColor: 'rgba(145, 158, 171, 0.32)',
                    fontSize: '12px',
                    color: 'rgba(145, 158, 171, 0.8)',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '12px',
                  }}
                  >
                    Photo will display here after uploaded
                  </div>
                )
              }
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              {
                editState ? workerPrivi.update === true && (
                  <div style={{ margin: '16px 0px 16px 0px' }}>
                    <FileBase64 multiple onDone={(image) => uploadImage(image, 'photoURL')} />
                  </div>
                ) : (
                  <div style={{ margin: '16px 0px 16px 0px' }}>
                    <FileBase64 multiple onDone={(image) => uploadImage(image, 'photoURL')} />
                  </div>
                )
              }
            </div>

          </div>
        </div>
      </Box>
      <div style={{ marginBottom: '10px' }}>
        <Typography variant="h5">Worker Information</Typography>
      </div>
      <Box sx={{
        display: 'grid',
        gridAutoColumns: '1fr',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr',
        gap: '6px 12px',
        gridTemplateAreas:
          '. .',
      }}
      >
        <div>
          <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
            <TextField id="standard-basic" label="First Name" sx={{ width: '100%' }} disabled={editState ? !workerPrivi.update : false} value={workerInput.firstName} onChange={(e) => handleOnChanged(e.target.value, 'firstName')} />
          </FormControl>
          <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
            <TextField id="standard-basic" label="Middle Name" sx={{ width: '100%' }} disabled={editState ? !workerPrivi.update : false} value={workerInput.middleName} onChange={(e) => handleOnChanged(e.target.value, 'middleName')} />
          </FormControl>
          <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
            <TextField id="standard-basic" label="Last Name" sx={{ width: '100%' }} disabled={editState ? !workerPrivi.update : false} value={workerInput.lastName} onChange={(e) => handleOnChanged(e.target.value, 'lastName')} />
          </FormControl>
          <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
            <Autocomplete
              value={workerInput.nationality}
              getOptionLabel={(option) => option.name ?? option.name}
              onChange={(e, newValue, reason) => {
                handleChangeAutocomplete(e, newValue, 'nationality', reason);
              }}
              options={countryListAllIsoData}
              disabled={editState ? !workerPrivi.update : false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  labelId="demo-simple-select-standard-label"
                  label="Nationality"
                />
              )}
            />
          </FormControl>
          <FormControl sx={{
            marginBottom: '8px',
            display: 'grid',
            gridAutoColumns: '1fr',
            gridTemplateColumns: '1fr 1fr',
            gap: '0px 8px',
            gridTemplateAreas: '. .',
            justifyContent: 'center',
          }}
          >
            <MuiPhoneNumber
              value={workerInput.phone}
              disabled={editState ? !workerPrivi.update : false}
              id="outlined-basic"
              defaultCountry="my"
              variant="outlined"
              onChange={(e, value) => handleChangePhoneNumber(e, value, 'phone')}
              autoFormat={false}
            />
            <Autocomplete
              //   loading={loadings}
              value={workerInput.gender}
              getOptionLabel={(option) => option.name ?? option}
              //   disabled={userType !== 'SUPER_ADMIN'}
              //   filterOptions={userfilterOptions}
              onChange={(e, newValue, reason) => {
                handleChangeAutocomplete(e, newValue, 'gender', reason);
              }}
              disabled={editState ? !workerPrivi.update : false}
              options={genderList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  labelId="demo-simple-select-standard-label"
                  label="Gender"
                />
              )}
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{
            marginBottom: '8px',
            display: 'grid',
            gridAutoColumns: '1fr',
            gridTemplateColumns: '1fr 1fr',
            gap: '0px 8px',
            gridTemplateAreas: '. .',
            justifyContent: 'center',
          }}
          >
            <TextField required id="standard-basic" label="Passport No" value={workerInput.passportNumber} disabled={editState ? !workerPrivi.update : false} sx={{ width: '100%' }} onChange={(e) => handleOnChanged(e.target.value, 'passportNumber')} />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Passport Expiry"
                inputFormat="dd/MM/yyyy"
                value={workerInput.passportExpiry}
                disabled={editState ? !workerPrivi.update : false}
                onChange={(newValue) => {
                  setWorkerInput({ ...workerInput, passportExpiry: newValue });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
            <Autocomplete
              value={workerInput.company.name}
              getOptionLabel={(option) => option.name ?? option}
              onChange={(e, newValue, reason) => {
                handleChangeCompanyAutocomplete(e, newValue, 'company', reason);
              }}
              disabled={editState ? !workerPrivi.update : false}
              options={companyList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  labelId="demo-simple-select-standard-label"
                  label="Company"
                />
              )}
            />
          </FormControl>
          <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Birth Date"
                inputFormat="dd/MM/yyyy"
                disableFuture
                disabled={editState ? !workerPrivi.update : false}
                value={workerInput.birthDate}
                onChange={(newValue) => {
                  setWorkerInput({ ...workerInput, birthDate: newValue });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
            <TextField id="standard-basic" label="Email" value={workerInput.email} disabled={editState ? !workerPrivi.update : false} type="email" onChange={(e) => handleOnChanged(e.target.value, 'email')} />
          </FormControl>
        </div>
        {/* {
          !editState && (
          <div>
            <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
              <Autocomplete
                value={issueReasonReducer(workerInput.card.issueReason)}
                getOptionLabel={(option) => option.name ?? option}
                onChange={(e, newValue, reason) => {
                  handleChangeCard(e, newValue, 'issueReason', reason);
                }}
                disabled={editState}
                options={workerIssueReasonList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    labelId="demo-simple-select-standard-label"
                    label="Card Issue Reason"
                  />
                )}
              />
            </FormControl>
            <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disabled={editState}
                  label="Valid Card From"
                  inputFormat="dd/MM/yyyy"
                  disablePast
                  value={workerInput.card.validFrom}
                  onChange={(newValue) => {
                    const dateRangeOneYear = moment(newValue).add(1, 'years').format();
                    setWorkerInput({ ...workerInput, card: { validTo: dateRangeOneYear, validFrom: newValue } });
                  }}
                  renderInput={(params) => <TextField {...params} required />}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disabled={editState}
                  label="Valid Card To"
                  inputFormat="dd/MM/yyyy"
                  disablePast
                  value={workerInput.card.validTo}
                  onChange={(newValue) => {
                    setWorkerInput({ ...workerInput, card: { ...workerInput.card, validTo: newValue } });
                  }}
                  renderInput={(params) => <TextField {...params} required />}
                />
              </LocalizationProvider>
            </FormControl>
          </div>
          )
        } */}
      </Box>
      <>
        <div style={{ marginBottom: '10px' }}>
          <Typography variant="h5">Card Information</Typography>
        </div>
        <Box sx={{
          display: 'grid',
          gridAutoColumns: '1fr',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: '1fr',
          gap: '6px 12px',
          gridTemplateAreas:
              '. .',
        }}
        >
          <div>
            <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
              <TextField id="standard-basic" label="Card Id" sx={{ width: '100%' }} disabled value={cardDisplay.cardId ? cardDisplay.cardId.toUpperCase() : '-'} />
            </FormControl>
            <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
              <TextField id="standard-basic" label="Card Issued" sx={{ width: '100%' }} disabled value={cardDisplay.validFrom ? moment(cardDisplay.validFrom).format('DD-MM-YYYY') : '-'} />
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
              <TextField id="standard-basic" label="Card Status" sx={{ width: '100%' }} disabled value={cardDisplay.status ? displayCardStatus(cardDisplay.status).toUpperCase() : '-'} />
            </FormControl>
            <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Card Expiry"
                  inputFormat="dd/MM/yyyy"
                  value={workerInput.validTo}
                  disablePast
                  onChange={(newValue) => {
                    setWorkerInput({ ...workerInput, validTo: newValue });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </div>
        </Box>
      </>
      <Box sx={editState ? { display: 'flex', justifyContent: 'space-between', width: '100%' } : { display: 'flex', justifyContent: 'end', width: '100%' }}>
        {
          editState && (
            <Stack
              spacing={2}
              direction="row"
              sx={{
                marginTop: '16px',
              }}
            >
              <Button variant="outlined" disabled={!workerPrivi.update} onClick={handlePrintCardModal} startIcon={<BadgeRoundedIcon />}>Print Card</Button>
            </Stack>
          )
        }
        <Stack
          spacing={2}
          direction="row"
          sx={{
            marginTop: '16px',
          }}
        >
          <Button variant="outlined" sx={{ width: 90 }} onClick={() => removeState()}>Cancel</Button>
          <Button variant="contained" sx={{ width: 90 }} disabled={!btnDisabled} onClick={() => handleSaveButton(workerInput)}>Save</Button>
        </Stack>
      </Box>
      <DeleteModal
        handleDelete={deleteButton}
        modalDel={deleteModal}
        handleDeleteClose={handleDeleteClose}
        lable="Are you sure you want to delete?"
        data={editValue && editValue._id}
      />
      <PrintJob
        handleDeleteClose={handleDeletePrintCardClose}
        setCardPrint={setCardPrint}
        cardPrint={cardPrint}
        editValue={editValue}
        setEditValue={setEditValue}
        cardExpiryDate={cardExpiryDate}
        setCardExpiryModel={setCardExpiryModel}
        setBorderDisplay={setBorderDisplay}
        borderDisplay={borderDisplay}
        workerPrivi={workerPrivi}
        cardIdDisplay={cardIdDisplay}
        cardDisplay={cardDisplay}
        setCardDisplay={setCardDisplay}
      />
      {
        viewOpen && (
          <ImageView
            setViewOpen={setViewOpen}
            viewOpen={viewOpen}
            data={imgDisplay}
            widths="80vw"
            heights="80vh"
          />
        )
      }
      {
        cardModal && (
          <ModalCardsWorker
            setViewOpen={setCardModal}
            viewOpen={cardModal}
            widths="80vw"
            workerId={editValue && editValue._id}
            apiCalled={apiCalled}
            workerPrivi={workerPrivi}
            setEditValue={setEditValue}
            setcallAPI={setcallAPI}
            setDataSet={setDataSet}
            dataSet={dataSet}
            sortingCardData={sortingCardData}
          />
        )
      }
      {
        hotlistModel && (
          <HotlistModel
            setViewOpen={setHotlistModel}
            viewOpen={hotlistModel}
            hotListed={hotListed}
            isHotlist={workerInput.isHotlisted}
            widths="40vw"
            dispHotlistReason={workerInput.hotlistReason}
            setWorkerInput={setWorkerInput}
            workerInput={workerInput}
          // heights="25vh"
          />
        )
      }
    </Box>
  );
};

FormWorker.propTypes = {
  style: PropTypes.object,
  editState: PropTypes.bool,
  editValue: PropTypes.object,
  setEditValue: PropTypes.func,
  removeAllState: PropTypes.func,
  setErrMsg: PropTypes.func,
  setOpen: PropTypes.func,
  setVariant: PropTypes.func,
  apiCalled: PropTypes.func,
  workerPrivi: PropTypes.object,
  setcallAPI: PropTypes.func,
  detailsPageAPICalled: PropTypes.func,
  setDataSet: PropTypes.func,
  dataSet: PropTypes.array,
  sortingCardData: PropTypes.func,
};

export default FormWorker;
