import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
// material
import { styled, alpha } from '@mui/material/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(0.5),
    minWidth: 143,
    color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: '1px !important',
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

export default function UserListToolbar({
  numSelected,
  filterName,
  onFilterName,
  labelName,
  popupfilter,
  displayFilter,
  popupBulkUpdate,
  functionUpdatePrint,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
      }}>
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder={labelName}
          sx={{
            background: '#FFF',
          }}
          startAdornment={(
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          )}
        />
        {numSelected > 0 && (
          <Typography component="div" variant="subtitle1">
            {numSelected}
            {' '}
            selected
          </Typography>
        )}
      </div>

      {numSelected > 1 ? (
        <>
          <Button
            variant="contained"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            disableElevation
            onClick={handleClick}
            startIcon={<Iconify icon="eva:chevron-down-outline" />}
            sx={{ width: 147 }}
          >
            Bulk Action
          </Button>
          <StyledMenu
            MenuListProps={{
              'aria-labelledby': 'demo-customized-menu',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={(event) => popupBulkUpdate(event.currentTarget)} sx={{ display: 'flex', alignItems: 'center' }} disableRipple>
              <UpdateIcon />
              <div style={{ fontSize: '14px' }}>Bulk update</div>
            </MenuItem>
            <MenuItem onClick={() => { functionUpdatePrint(); setAnchorEl(null); }} sx={{ display: 'flex', alignItems: 'center' }} disableRipple>
              <DynamicFeedIcon />
              <div style={{ fontSize: '14px' }}>Bulk printing</div>
            </MenuItem>
          </StyledMenu>
        </>
      ) : displayFilter && (
        <Tooltip title="Filter list">
          <IconButton onClick={(event) => popupfilter(event.currentTarget)}>
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
}

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  labelName: PropTypes.string,
  popupfilter: PropTypes.func,
  displayFilter: PropTypes.bool,
  popupBulkUpdate: PropTypes.bool,
  functionUpdatePrint: PropTypes.func,
  // bulkExpDateFunction: PropTypes.func,
};
