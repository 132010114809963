/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable no-useless-escape */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Base64 } from 'js-base64';
import {
  TextField,
  Box,
  Typography,
  Card,
  FormControl,
  Stack,
  Button,
  Divider,
  FormGroup,
  Checkbox,
  FormLabel,
  FormControlLabel,
  Autocomplete,
  CircularProgress,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import Cookies from 'js-cookie';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MuiPhoneNumber from 'material-ui-phone-number';
import { TableListHead } from '../../../sections/@dashboard/user';
import Scrollbar from '../../Scrollbar';
import {
  APICreateCompany,
  APIUpdateCompany,
  APIDeleteCompany,
} from '../../../utils/apiCalled';
import {
  AllMandatoryFilled,
  requiredField,
  genderList,
  statusList,
  userTypelist,
  circularLoadingStyle,
//   workerIssueReasonList,
} from '../../../utils/config';
import DeleteModal from '../../DeleteModal';

const TABLE_HEAD = [
  { id: 'fullName', label: 'Full Name', alignRight: false },
  { id: 'nricNumber', label: 'IC Number', alignRight: false },
  { id: 'phoneNo', label: 'Phone Number', alignRight: false },
];

const FormCompany = ({
  style, editState, setEditValue, editValue, removeAllState, setOpen, setVariant, apiCalled, userPrivi, setErrMsg,
}) => {
  const token = Cookies.get('token');

  // console.log('editValue', editValue);

  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [addressErr, setAddressErr] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  const [companyInput, setCompanyInput] = useState({
    name: editState ? editValue.name : null,
    address: editState ? editValue.address : null,
    industry: editState ? editValue.industry : null,
  });

  const handleCompanyNameField = (e) => {
    const maxLength = 255;
    if (e.target.value.length <= maxLength) {
      setCompanyInput({ ...companyInput, [e.target.name]: e.target.value });
      setNameErr(false);
    } else {
      setNameErr(true);
      setBtnDisabled(false);
    }
  };

  const handleCompanyAddressField = (e) => {
    const maxLength = 500;
    if (e.target.value.length <= maxLength) {
      setCompanyInput({ ...companyInput, [e.target.name]: e.target.value });
      setAddressErr(false);
    } else {
      setAddressErr(true);
      setBtnDisabled(false);
    }
  };

  const handleTextFieldChange = (e) => {
    setCompanyInput({ ...companyInput, [e.target.name]: e.target.value });
  };

  const removeState = () => {
    removeAllState();
    setCompanyInput({
      name: null,
      address: null,
      industry: null,
      representatives: null,
    });
  };

  const handleSaveButton = async () => {
    try {
      const result = editState ? await APIUpdateCompany(companyInput, editValue._id, setLoading, token) : await APICreateCompany(companyInput, setLoading, token);
      // console.log('result', result);
      if (result.data && result.data.status === 200) {
        setLoading(false);
        setOpen(true);
        setErrMsg('Thank you! Your submission has been sent!');
        setVariant('success');
        apiCalled();
        removeAllState();
      } else {
        setLoading(false);
        setOpen(true);
        setErrMsg(`Error! ${result.response.data.message}`);
        setVariant('error');
      }
    } catch (err) {
      setOpen(true);
      setErrMsg(`API Function Error, ${err}`);
      setVariant('error');
    }
  };

  const checkValidationCompany = () => {
    const checkedValidation = AllMandatoryFilled(requiredField.company, companyInput);
    return checkedValidation;
  };

  const checkDetailChanges = () => {
    const input = {};

    if (companyInput.name !== editValue.name) {
      input.name = companyInput.name;
    }
    if (companyInput.industry !== editValue.industry) {
      input.name = companyInput.name;
    }
    if (companyInput.address !== editValue.address) {
      input.name = companyInput.name;
    }
    const checking = Object.keys(input).length > 0;
    return checking;
  };

  const deleteButton = async () => {
    try {
      const result = await APIDeleteCompany(editValue._id, setLoading, token);
      if (result.data && result.data.status === 200) {
        setOpen(true);
        setErrMsg('Successfully deleted!');
        setVariant('success');
        apiCalled();
        removeAllState();
      } else {
        setLoading(false);
        setOpen(true);
        setErrMsg(`Error! ${result.response.data.message}`);
        setVariant('error');
      }
    } catch (err) {
      setOpen(true);
      setErrMsg(`API Function Error, ${err}`);
      setVariant('success');
    }
  };

  const handleDeleteClose = () => {
    setDeleteModal(false);
  };

  useEffect(() => {
    if (editState) {
      if (checkDetailChanges() && checkValidationCompany()) {
        setBtnDisabled(true);
      } else {
        setBtnDisabled(false);
      }
    } else if (checkValidationCompany()) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [companyInput]);

  return (
    <Box style={style}>
      <Box>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Typography variant="h6">{editState ? `${editValue.name}` : 'Add Company'}</Typography>
          </Stack>
          { loading && <CircularProgress color="success" sx={circularLoadingStyle} /> }
          {
            editState && (
            <Stack
              spacing={2}
              direction="row"
            >
              <Button variant="outlined" sx={{ width: 90 }} onClick={() => setDeleteModal(true)} startIcon={<DeleteRoundedIcon />}>Delete</Button>
            </Stack>
            )
          }
        </div>
      </Box>
      <Box sx={{
        display: 'grid',
        gridAutoColumns: '1fr',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr',
        gap: '6px 12px',
        gridTemplateAreas:
                        '. .',
        marginBottom: '16px',
      }}
      >
        <div>
          <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
            <TextField
              required
              error={nameErr}
              helperText={nameErr ? 'Maximum of 255 characters allowed' : ''}
              id="standard-basic"
              label="Company Name"
              sx={{ width: '100%' }}
              value={companyInput.name}
              name="name"
              onChange={(e) => handleCompanyNameField(e)}
            />
          </FormControl>
          <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
            <TextField
              id="standard-basic"
              label="Industry"
              sx={{ width: '100%' }}
              value={companyInput.industry}
              name="industry"
              onChange={(e) => handleTextFieldChange(e)}
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ marginBottom: '8px', width: '100%' }}>
            <TextField
              InputProps={{
                style: {
                  padding: '14px 14px',
                },
              }}
              error={addressErr}
              helperText={addressErr ? 'Maximum of 500 characters allowed' : ''}
              id="standard-basic"
              label="Company Address"
              sx={{ width: '100%' }}
              value={companyInput.address}
              name="address"
              onChange={(e) => handleCompanyAddressField(e)}
              multiline
              rows={4}
            />
          </FormControl>
        </div>
      </Box>
      {editState && (
        <>
          <Box>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
              <Stack spacing={2} sx={{ width: '100%' }}>
                <Typography variant="h6">Company Representatives</Typography>
              </Stack>
            </div>
          </Box>
          <Box sx={{
            display: 'grid',
            gridAutoColumns: '1fr',
            // gridTemplateColumns: '1fr 1fr',
            // gridTemplateRows: '1fr',
            gap: '6px 12px',
            marginBottom: '16px',
          }}
          >
            <div>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800, maxHeight: 340 }}>
                  <Table stickyHeader>
                    <TableListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={editValue.representatives.length}
                      numSelected={selected.length}
                    />
                    {editValue && editValue.representatives.length > 0 ? (
                      <TableBody>
                        {editValue.representatives.map((row) => {
                          const {
                            _id, firstName, lastName, contactCode, contactNo, icNumber,
                          } = row;

                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                            >
                              <TableCell align="left" sx={{ cursor: 'pointer' }} onClick={() => {}}>{`${firstName} ${lastName}`}</TableCell>
                              <TableCell align="left" sx={{ cursor: 'pointer' }} onClick={() => {}}>{icNumber}</TableCell>
                              <TableCell align="left" sx={{ cursor: 'pointer' }} onClick={() => {}}>{`${contactCode}${contactNo}`}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableCell colSpan={6}>
                          <Box sx={{
                            display: 'flex', justifyContent: 'center', width: '100%', height: '100%',
                          }}
                          >
                            No representatives assigned
                          </Box>
                        </TableCell>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
            </div>
          </Box>
        </>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
        <Stack
          spacing={2}
          direction="row"
          sx={{
            marginTop: '16px',
          }}
        >
          <Button variant="outlined" sx={{ width: 90 }} onClick={() => removeState()}>Cancel</Button>
          <Button variant="contained" sx={{ width: 90 }} disabled={!btnDisabled} onClick={() => handleSaveButton()}>Save</Button>
        </Stack>
      </Box>
      <DeleteModal
        handleDelete={deleteButton}
        modalDel={deleteModal}
        handleDeleteClose={handleDeleteClose}
        label="Are you sure you want to delete?"
        data={editValue && editValue._id}
      />
    </Box>
  );
};

FormCompany.propTypes = {
  style: PropTypes.object,
  editState: PropTypes.bool,
  editValue: PropTypes.object,
  setEditValue: PropTypes.func,
  removeAllState: PropTypes.func,
  setErrMsg: PropTypes.func,
  setOpen: PropTypes.func,
  setVariant: PropTypes.func,
  apiCalled: PropTypes.func,
  userPrivi: PropTypes.array,
};

export default FormCompany;
