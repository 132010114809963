/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
} from '@mui/material';
import styled from '@emotion/styled';
import { formatDate } from '../../../utils/config';
import { countryListAllIsoData } from '../../../listOfCountry';
import './BulkPrintJob.css';

const PageBreakWrapper = styled.div`
  && {
    page-break-after: always;
  }
`;


const styleCard = {
  position: 'relative',
  width: '8.9cm',
  height: '5.1cm',
  backgroundColor: '#FFF',
  // float: 'left',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gridTemplateRows: '1fr',
  gridAutoColumns: 'auto',
};

const withBorder = {
  border: '0.5px solid #000000',
  paddingBottom: '20px',
};

const titlefontSize = {
  fontSize: '4.5pt',
  fontWeight: 'normal',
  fontFamily: 'Open Sans',
};

const subTitleFontSize = {
  fontSize: '5.5pt',
  fontWeight: 'bold',
  fontFamily: 'Noto Sans Mono',
};

const gridColumnText = {
  display: 'grid',
  // gridAutoColumns: '1fr',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr',
  gap: '0px 11px',
  width: '146px',
  wordWrap: 'break-word',
  // marginBottom: '8px',
};

const gridColumnDetails = {
  display: 'grid',
  gridTemplateColumns: 'fit-content(100%) fit-content(100%) fit-content(100%)',
  gridTemplateRows: '1fr',
  gap: '0px 21px',
  marginBottom: '9px',
};

const gridDetailsRows = {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr 1fr',
  width: 'fit-content',
  gap: '7px',
};

const gridColumnSecondRow = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateRows: '1fr',
  gap: '0px 11px',
};

const gridColumnNameRow = {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr',
};

const gridColumnFirstRow = {
  display: 'grid',
  // gridAutoColumns: '1fr',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateRows: '1fr',
  gap: '0px 11px',
};

const fontSizeCardId = {
  display: 'grid',
  fontSize: '5.5pt',
  fontWeight: 'bold',
  fontFamily: 'Noto Sans Mono',
  marginTop: '20px',
  // marginLeft: '2px',
};

const nameCard = {
  fontWeight: 'bold',
  fontFamily: 'Noto Sans Mono',
  fontSize: '7pt',
  // height: '28.2px',
};

const detailContainer = {
  display: 'grid',
  gridAutoColumns: '1fr',
  gridTemplateColumns: 'fit-content(100%)',
  gridTemplateRows: '24px fit-content(100%) fit-content(100%)',
  float: 'right',
  marginLeft: '4px',
  marginTop: '60px',
};

const gridDetailsRowContainer ={ 
  display: 'grid',
  // gap: '3px',
};
const BulkPrintTemplate = (value) => {
  // console.log('bulkPrintTemplate', {
  //   value,
  // });

  const country = useMemo(() => {
    const filterCountry = value.detail.nationality ? countryListAllIsoData.filter((c) => c.code3 === value.detail.nationality) : '-';
    return filterCountry[0].name;
  }, []);

  const uppercaseValue = (cardValue) => {
    const valueUppercase = cardValue !== undefined ? cardValue.toUpperCase() : null;
    return valueUppercase;
  };

  const swithValueFirstName = () => {
    let stringValue = '';
    if (value.detail.firstName && value.detail.middleName && value.detail.lastName) {
      stringValue = `${uppercaseValue(value.detail.firstName)} ${uppercaseValue(value.detail.middleName)} ${uppercaseValue(value.detail.lastName)}`;
    } else if (value.detail.firstName) {
      if (value.detail.middleName && value.detail.lastName) {
        stringValue = `${uppercaseValue(value.detail.firstName)} ${uppercaseValue(value.detail.middleName)} ${uppercaseValue(value.detail.lastName)}`;
      } else if (value.detail.middleName) {
        stringValue = `${uppercaseValue(value.detail.firstName)} ${uppercaseValue(value.detail.middleName)}`;
      } else if (value.detail.lastName) {
        stringValue = `${uppercaseValue(value.detail.firstName)} ${uppercaseValue(value.detail.lastName)}`;
      } else if (value.detail.firstName) {
        stringValue = `${uppercaseValue(value.detail.firstName)}`
      } else {
        stringValue = '-'
      }
    } else if (value.detail.middleName) {
      if (value.detail.lastName) {
        stringValue = `${uppercaseValue(value.detail.middleName)} ${uppercaseValue(value.detail.lastName)}`
      } else if (value.detail.middleName) {
        stringValue = `${uppercaseValue(value.detail.middleName)}`
      } else {
        stringValue = '-'
      }
    } else if (value.detail.lastName) {
      stringValue = `${uppercaseValue(value.detail.lastName)}`
    } else {
      stringValue = '-'
    }

    return stringValue;
  };

  return (
    <div style={{
      width: '86mm',
      height: '54mm',
      pageBreakAfter: 'always',
    }}>
     <div style={styleCard} className="font-text">
          <div style={{
            // marginTop: '30px',
            height: '113px',
            float: 'left',
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr 1fr',
            marginTop: '86.4px',
            marginLeft: '14px',
            // position: 'absolute',
          }}
          >
              <Avatar
                srcSet={value.detail.photoURL}
                variant="square"
                sizes="small"
                sx={{
                  height: '90px',
                  width: '90px',
                  // borderRadius: '8px',
                  marginLeft: '4px',
                  position: '',
                  display: 'grid',
                  '& .MuiAvatar-img': {
                    objectFit: 'contain'
                  }
                }}
              />
            <div style={fontSizeCardId}>
              {/* {cardIdDisplay.toUpperCase()} */}
              {value.detail.card.cardId ? value.detail.card.cardId.toUpperCase() : ''}
            </div>
        </div>
        <div style={detailContainer}>
            <div style={gridColumnNameRow}>
              <div style={nameCard}>
                {swithValueFirstName()}
              </div>
            </div>
            <div style={gridColumnDetails}>
              <div style={gridDetailsRows}>
                <div style={gridDetailsRowContainer}>
                  <div style={titlefontSize}>
                    Nationality
                  </div>
                  <div style={subTitleFontSize}>
                    {country ? country.toUpperCase() : '-'}
                  </div>
                </div>
                <div style={gridDetailsRowContainer}>
                  <div style={titlefontSize}>
                    Passport Number
                  </div>
                  <div style={subTitleFontSize}>
                  {value.detail.passportNumber ? value.detail.passportNumber : '-'}
                  </div>
                </div>
              </div>
              <div style={gridDetailsRows}>
                <div style={gridDetailsRowContainer}>
                  <div style={titlefontSize}>Expiry Date</div>
                  <div style={subTitleFontSize}>{value.detail.card.validTo ? formatDate(value.detail.card.validTo) : '-'}</div>
                </div>
                <div style={gridDetailsRowContainer}>
                  <div style={titlefontSize}>
                    Date of Birth
                  </div>
                  <div style={subTitleFontSize}>
                  {value.detail.birthDate ? formatDate(value.detail.birthDate) : '-'}
                  </div>
                </div>
              </div>
              <div style={gridDetailsRows}>
                <div></div>
                <div style={gridDetailsRowContainer}>
                  <div style={titlefontSize}>Gender</div>
                  <div style={subTitleFontSize}>{value.detail.gender ? value.detail.gender.substring(0, 1).toUpperCase() : '-'}</div>
                </div>
              </div>
            </div>
          {/* <div style={gridColumnFirstRow}>
            <div>
              <div style={titlefontSize}>
                Nationality
              </div>
              <div style={subTitleFontSize}>
                {country ? country.toUpperCase() : '-'}
              </div>
            </div>
            <div>
              <div style={titlefontSize}>Expiry Date</div>
              <div style={subTitleFontSize}>{value.detail.validTo ? formatDate(value.detail.validTo) : '-'}</div>
            </div>
          </div> */}
          {/* <div style={gridColumnSecondRow}>
            <div>
              <div style={titlefontSize}>
                Passport Number
              </div>
              <div style={subTitleFontSize}>
                {value.passportNumber ? value.passportNumber : '-'}
              </div>
            </div>
             <div>
              <div style={titlefontSize}>
                Date of Birth
              </div>
              <div style={subTitleFontSize}>
                {value.birthDate ? formatDate(value.birthDate) : '-'}
              </div>
            </div>
            <div>
              <div style={titlefontSize}>Gender</div>
              <div style={subTitleFontSize}>{value.gender ? value.gender.toUpperCase() : '-'}</div>
            </div>
          </div> */}
          <div style={gridColumnText}>
            <div style={gridDetailsRowContainer}>
              <div style={titlefontSize}>Place of Employment</div>
              <div style={subTitleFontSize}>{value.detail.companyAddress ? value.detail.companyAddress.toUpperCase() : '-'}</div>
            </div>
          </div>
        </div>
      </div>
      <style>
        {
        `
         .font-text {
          // -webkit-font-smoothing: none;
          // font-smooth: none;
          text-shadow:
            0 0 0px,
            0 0 0px,
            0 0 0px,
            0 0 0px,
            0 0 0px,
            0 0 0px,
            0 0 0px,
            0 0 0px,
            0 0 0px,
            0 0 0px,
            0 0 0px;
         }
        `
      }
      </style>
    </div>
  );
};

export default class BulkPrintJob extends React.Component {
  render() {
    const printingPages = [];

    const { details, borderDisplay } = this.props;
    // console.log('printingPages', { details, borderDisplay })
    for (const detail of details) {
      // console.log(detail);
      const tempTemplate = <BulkPrintTemplate detail={detail} borderDisplay={borderDisplay} />;
      printingPages.push(tempTemplate);
    }

    return (
      <div style={{
        // border: '0.5px solid #000000',
        // paddingBottom: '20px',
      }}
      >
        {printingPages}
      </div>
    );
  }
}
