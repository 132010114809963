// /* eslint-disable quote-props */
import axios from 'axios';

const authorization = {
  'X-CSCAPI-KEY': process.env.REACT_APP_COUNTRY_STATE_CITY_API_KEY,
};

const countryApiURL = process.env.REACT_APP_COUNTRY_STATE_CITY_ENDPOINT;

export const APIGetCountry = async (setLoading) => {
  setLoading(true);
  const result = await axios.get(countryApiURL, {
    headers: authorization,
  }).catch((error) => error);
  return result;
};
