/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// material
import {
  TableRow, TableCell, TableHead, Checkbox,
} from '@mui/material';

// ----------------------------------------------------------------------

export default function UserListHead({
  order,
  orderBy,
  headLabel,
  indeterminateState,
  checkedState,
  component,
  onSelectAllClick,
}) {

  return (
    <TableHead>
      <TableRow>
        {
          component === 'Worker'  && (
            <TableCell padding="checkbox">
              <Checkbox
                value="all"
                color="primary"
                indeterminate={indeterminateState}
                checked={checkedState}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'select all desserts',
                }}
              />
            </TableCell>
          )
        }
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

UserListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  headLabel: PropTypes.array,
};
