import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  width: '100vw',
  height: '100vh',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  // pt: 2,
  // px: 4,
};

export default function ImageView({
  setViewOpen, data, viewOpen, widths, heights,
}) {
  const handleClose = () => {
    setViewOpen(false);
  };
  return (
    <Modal
      open={viewOpen}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{ ...style }}>
        <Icon
          sx={{
            color: 'white', fontWeight: 700, cursor: 'pointer', position: 'absolute', top: 16, right: 16,
          }}
          onClick={handleClose}
        >
          <CloseRoundedIcon />
        </Icon>
        <Avatar
          alt="poiImage"
          src={data}
          variant="square"
          sx={{
            width: widths,
            height: heights,
            borderRadius: '8px',
            objectFit: 'cover',
            border: 0,
          }}
        />
      </Box>
    </Modal>
  );
}

ImageView.propTypes = {
  setViewOpen: PropTypes.func,
  viewOpen: PropTypes.bool,
  widths: PropTypes.string,
  heights: PropTypes.string,
  data: PropTypes.shape(),
};

ImageView.defaultProps = {
  setViewOpen: () => false,
  widths: '80vw',
  heights: '80vh',
  viewOpen: false,
  data: [],
};
