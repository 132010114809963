/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as Yup from 'yup';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Base64 } from 'js-base64';
import Cookies from 'js-cookie';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
// form
import { useFormik, Form, FormikProvider } from 'formik';
// @mui
import {
  Stack, IconButton, InputAdornment, TextField, Checkbox, Tooltip, Typography, Box,
} from '@mui/material';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { LoadingButton } from '@mui/lab';
import SnackBar from '../../../components/SnackBar';
// components
import Iconify from '../../../components/Iconify';
import { APILogin } from '../../../utils/apiCalled';
import { auth } from '../../../firebase';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const tooltipMsg = 'rememberMe message';

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formSubmit = async (emailDecode, passwordDecode, rememberMe) => {
    try {
      // parameter
      const para = {
        email: emailDecode,
        password: passwordDecode,
        remember: rememberMe,
      };
      // called the login API
      const resultData = await APILogin(para, setLoading);
      if (resultData.data && resultData.data.status === 200) {
        const {
          role, firstName, lastName, email, _id, token, privilege,
        } = resultData.data.data; // to get the details for cookies storage
        if (role === 'EMPLOYER' || role === 'ENFORCEMENT_OFFICER') {
          signOut(auth).then(() => {
            setOpen(null);
            Cookies.remove('firstName');
            Cookies.remove('lastName');
            Cookies.remove('email');
            Cookies.remove('token');
            Cookies.remove('role');
            Cookies.remove('id');
            Cookies.remove('privilege');
            setOpen(true);
            setErrMsg('Unauthorized to Login');
            setLoading(false);
          }).catch((error) => {
            // console.log(`Logout Error!: ${error}`);
          });
        } else if (role === 'FIC_ADMIN' && privilege) {
          Cookies.set('role', role);
          Cookies.set('id', _id);
          Cookies.set('email', email);
          Cookies.set('token', token);
          Cookies.set('firstName', firstName);
          Cookies.set('lastName', lastName);
          Cookies.set('privilege', JSON.stringify(privilege));
          setLoading(false);
          navigate('/dashboard/worker', { replace: true });
        } else {
          Cookies.set('role', role);
          Cookies.set('id', _id);
          Cookies.set('email', email);
          Cookies.set('token', token);
          Cookies.set('firstName', firstName);
          Cookies.set('lastName', lastName);
          // Cookies.set('privilege', JSON.stringify(privilege));
          setLoading(false);
          navigate('/dashboard/worker', { replace: true });
        }
      } else {
        signOut(auth).then(() => {
          setOpen(null);
          Cookies.remove('firstName');
          Cookies.remove('lastName');
          Cookies.remove('email');
          Cookies.remove('token');
          Cookies.remove('role');
          Cookies.remove('id');
          Cookies.remove('privilege');
          setOpen(true);
          setErrMsg('Login Failed!, Issues on the Login API');
          setLoading(false);
        }).catch((error) => {
          // console.log(`Logout Error!: ${error}`);
        });
      }
    } catch (error) {
      setOpen(true);
      setErrMsg(error);
      setLoading(false);
    }
  };

  // firebaseLogin
  const firebaseLogin = async (email, password, emailDecode, passwordDecode, rememberMe) => {
    try {
      setLoading(true);
      signInWithEmailAndPassword(auth, email, password)
        .then((result) => {
          const { user } = result;
          if (user) {
            formSubmit(emailDecode, passwordDecode, rememberMe);
          } else {
            setOpen(true);
            setErrMsg('Error!, No User');
            setLoading(false);
          }
        }).catch((error) => {
          // const errorCode = error.code;
          // const errorMessage = error.message;
          setOpen(true);
          setErrMsg('You have entered an invalid username or password');
          setLoading(false);
        });
    } catch (error) {
      setOpen(true);
      setErrMsg(error);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (value) => {
      const emailDecode = Base64.encode(value.email); // decode the email
      const passwordDecode = Base64.encode(value.password); //  decode the password
      /*
        ** API Login the parameter as per below
        @emailDecode - email that already encoded
        @passwordDecoed - password that already encoded
        @values.remember - remember me
      */
      firebaseLogin(value.email, value.password, emailDecode, passwordDecode, value.remember);
    },
  });

  const {
    errors, touched, values, handleSubmit, getFieldProps,
  } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <SnackBar alert="error" handleClose={() => setOpen(false)} open={open} errorMsg={errMsg} />
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" sx={{ my: 1 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          /> */}
          <Box sx={{ display: 'flex', alignItem: 'center', justifyContent: 'center' }}>
            <Checkbox sx={{ margin: 0, padding: 0 }} {...getFieldProps('remember')} checked={values.remember} />
            <Typography sx={{ position: 'relative', top: '2px', left: '4px' }}>Remember Me</Typography>
          </Box>
          <Tooltip title={tooltipMsg} placement="top">
            <IconButton>
              <InfoRoundedIcon sx={{ width: 12, height: 12 }} />
            </IconButton>
          </Tooltip>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
