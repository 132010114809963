/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Cookies from 'js-cookie';
import moment from 'moment';
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
  Autocomplete,
  TextField,
  Checkbox,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// components
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { TableListHead, TableListToolbar } from '../sections/@dashboard/user';
import { APIFetchAuditLog } from '../utils/apiCalled';
import SnackBar from '../components/SnackBar';
import MenuPopover from '../components/MenuPopover';
import TagsInput from '../components/TagsInput';
import { tableCircularLoadingStyle, rowsLimit } from '../utils/config';
import ListPagination from '../components/ListPagination';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'user', label: 'User', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
  { id: 'source', label: 'Source', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// ----------------------------------------------------------------------

export default function User() {
  const token = Cookies.get('token');
  // console.log(JSON.parse(userType));
  const [page, setPage] = useState(1); // pagination page
  const [searchTimeout, setSearchTimeout] = useState(null); // this for filterTimeout
  const [callAPI, setcallAPI] = useState(false); // indicator to call api for pagination
  const [pageTotal, setPageTotal] = useState(null); // total pagination
  const [filterName, setFilterName] = useState('');
  const [openFilterPopUp, setOpenFilterPopUp] = useState(null); // filter popup
  const [rowsPerPage, setRowsPerPage] = useState(10); // pagination limit rows
  const [auditData, setAuditData] = useState([]); // list of the workerData take from fetchWorker API
  const [loading, setLoading] = useState(false); // all the loading state (when called the API)
  const [open, setOpen] = useState(false); // open snackbar
  const [errMsg, setErrMsg] = useState(''); // error message snackbar
  const [variant, setVariant] = useState('');
  const [listAction, setListAction] = useState([]);
  const [apiCalledComponent, setApiCalledComponent] = useState(false); // this to call useEffect API if on different component.
  const [getFilter, setGetFilter] = useState({
    email: null,
    action: null,
    actionParameters: null,
    target: null,
    dateFrom: null,
    dateTo: null,
  });
  const [dupFilter, setDupFilter] = useState({
    email: [],
    action: [],
    actionParameters: [],
    target: [],
  });

  const apiCalled = async (params, filterEmail) => {
    try {
      // console.log('paramsss', params);
      // console.log('filterEmail', filterEmail);
      const result = await APIFetchAuditLog(params, filterEmail, rowsPerPage, page, setLoading, token);
      // console.log(result);
      if (result.data && result.data.status === 200) {
        const { data, totalPage, options } = result.data;
        setPageTotal(totalPage);
        setLoading(false);
        setListAction(options);
        setAuditData(data);
      } else {
        setLoading(false);
        setOpen(true);
        setVariant('error');
        setErrMsg(`Error! ${result.response.data.message}`);
        // console.log('error from API');
      }
    } catch (err) {
      setLoading(false);
      setVariant('error');
      setOpen(true);
      setErrMsg(`Error! : ${err} Please try again`);
    }
  };

  // console.log(listAction);

  // this for first time come to the page
  useEffect(() => {
    if (token) {
      apiCalled(getFilter);
    }
    return () => {
      setAuditData([]);
    };
  }, []);

  // this to call when click limit or pagination
  useEffect(() => {
    if (callAPI) {
      apiCalled(getFilter);
      setcallAPI(false);
    }
  }, [callAPI]);

  useEffect(() => {
    if (apiCalledComponent) {
      apiCalled(getFilter, filterName);
      setApiCalledComponent(false);
    }
  }, [apiCalledComponent]);

  const handleSelection = (e) => {
    setRowsPerPage(e.target.value);
    setPage(1);
    setcallAPI(true);
  };

  const handlePagination = (e, pageNo) => {
    setPage(pageNo);
    setcallAPI(true);
  };

  const closePopup = () => {
    setOpenFilterPopUp(null);
  };

  const selectedTagsFilter = (items, name) => {
    const arrayJoin = items ? items.join() : null;
    setGetFilter({ ...getFilter, [name]: arrayJoin });
  };

  const filterButton = () => {
    apiCalled(getFilter, filterName);
  };

  const clearFilterButton = () => {
    setGetFilter({
      email: null,
      action: null,
      actionParameters: null,
      target: null,
      dateFrom: null,
      dateTo: null,
    });
    setDupFilter({
      email: [],
      action: [],
      actionParameters: [],
      target: [],
    });
    setApiCalledComponent(true);
  };

  // this is for filter
  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    // this to call api and setTimeout to 0.2 sec
    setSearchTimeout(
      setTimeout(() => {
        apiCalled(getFilter, filterName);
        setPage(1);
      }, 200),
    );
    return () => clearTimeout(searchTimeout); // this also to clear time out
  }, [filterName]);

  const handleFilterByName = (event) => {
    // setGetFilter({ ...getFilter, user: event.target.value });
    setFilterName(event.target.value);
  };

  // console.log(getFilter);

  return (
    <Page title="Audit">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Audit Logs
          </Typography>
          {loading && <CircularProgress color="success" sx={tableCircularLoadingStyle} />}
        </Stack>
        <SnackBar alert={variant} handleClose={() => setOpen(false)} open={open} errorMsg={errMsg} />
        <Card>
          <TableListToolbar filterName={filterName} onFilterName={handleFilterByName} labelName="Search email..." popupfilter={setOpenFilterPopUp} displayFilter />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableListHead
                  headLabel={TABLE_HEAD}
                />
                <MenuPopover open={Boolean(openFilterPopUp)} anchorEl={openFilterPopUp} onClose={closePopup} styleWidth={500}>
                  <Box>
                    <Typography variant="subtitle1" sx={{ paddingTop: 1, paddingBottom: 2, paddingLeft: 1 }}>Filter By</Typography>
                    <TagsInput fullWidth variant="outlined" id="email" name="email" placeholder="Add tags" label="Email" dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="email" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      options={listAction}
                      value={dupFilter.action}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.value ?? option}
                      onChange={(event, value) => {
                        setDupFilter({ ...dupFilter, action: value });
                        selectedTagsFilter(value.map((x) => x._id), 'action');
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.value}
                        </li>
                      )}
                      sx={{ marginBottom: 1.5 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Action" placeholder="Add tags" InputLabelProps={{ shrink: true }} />
                      )}
                    />
                    <TagsInput fullWidth variant="outlined" id="actionParameter" name="actionParameter" placeholder="Add tags" label="Action Parameters" dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="actionParameters" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                    <TagsInput fullWidth variant="outlined" id="source" name="source" placeholder="Add tags" label="Source" dupFilter={dupFilter} setDupFilter={setDupFilter} getFilter={getFilter} setGetFilter={setGetFilter} keyName="target" sx={{ marginBottom: 1.5 }} />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="From"
                        fullWidth
                        inputFormat="dd/MM/yyyy"
                        value={getFilter.dateFrom}
                        onChange={(newValue) => {
                          setGetFilter({ ...getFilter, dateFrom: moment(newValue).set({ hour: 0, minute: 0, second: 0 }).format() });
                        }}
                        renderInput={(params) => <TextField {...params} sx={{ marginBottom: 1.5 }} fullWidth label="From" placeholder="Log date" InputLabelProps={{ shrink: true }} />}
                      />
                      <DatePicker
                        label="To"
                        // fullWidth
                        inputFormat="dd/MM/yyyy"
                        value={getFilter.dateTo}
                        onChange={(newValue) => {
                          setGetFilter({ ...getFilter, dateTo: moment(newValue).set({ hour: 23, minute: 59, second: 59 }).format() });
                        }}
                        renderInput={(params) => <TextField {...params} sx={{ marginBottom: 1.5 }} fullWidth label="To" placeholder="Log date" InputLabelProps={{ shrink: true }} />}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <Button variant="outlined" sx={{ width: 110, marginRight: '8px' }} component={RouterLink} to="#" onClick={() => clearFilterButton()}>
                      Clear Filter
                    </Button>
                    <Button variant="contained" sx={{ width: 110 }} component={RouterLink} to="#" onClick={() => filterButton()}>
                      Filter
                    </Button>
                  </Box>
                </MenuPopover>
                <TableBody>
                  {auditData.length > 0 && auditData.map((row) => {
                    const {
                      _id, target, date, user, action,
                    } = row;
                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell align="left">{Object.keys(user).length > 0 ? `${user.name} (${user.email})` : '-'}</TableCell>
                        <TableCell align="left">{action && Object.keys(action).length > 0 ? action.message : '-'}</TableCell>
                        <TableCell align="left">{target || '-'}</TableCell>
                        <TableCell align="left" sx={{ width: '200px' }}>{date ? moment(date).format('DD/MM/YYYY hh:mm A') : '-'}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <Box sx={{ margin: '16px 8px' }}>
            <ListPagination count={pageTotal} handlePagination={handlePagination} limitValue={rowsPerPage} listofLimit={rowsLimit} handleSelection={handleSelection} />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
