/* eslint-disable max-len */
/* eslint-disable quote-props */
import axios from 'axios';

const url = process.env.REACT_APP_API_URL;
// called Login API
export const APILogin = async (para, setLoading) => {
  setLoading(true);
  const result = await axios.post(`${url}/login`, para, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY,
    },
  }).catch((error) => error);
  return result;
};

// FetchWorker
export const APIFetchWorker = async (params, passportNoFilter, limitParams, paginationParams, setLoading, workerId, tokens) => {
  setLoading(true);
  const result = await axios.get(`${url}/worker`, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY,
      'Authorization': tokens,
    },
    params: {
      id: workerId,
      name: params && params.name,
      status: params && params.status,
      phone: params && params.phone,
      gender: params && params.gender,
      nationality: params && params.nationality,
      company: params && params.company,
      email: params && params.email,
      passportNumber: passportNoFilter && passportNoFilter,
      pageNo: paginationParams,
      totalPerPage: limitParams,
    },
  }).catch((error) => error);
  return result;
};

export const APIFetchAuditLog = async (params, filterEmail, limitParams, paginationParams, setLoading, tokens) => {
  setLoading(true);
  const result = await axios.get(`${url}/auditLog`, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY,
      'Authorization': tokens,
    },
    params: {
      email: filterEmail || params.email,
      action: params && params.action,
      actionParameters: params && params.actionParameters,
      target: params && params.target,
      dateFrom: params && params.dateFrom,
      dateTo: params && params.dateTo,
      pageNo: paginationParams,
      totalPerPage: limitParams,
    },
  }).catch((error) => error);
  return result;
};

export const APIFetchUser = async (nameParams, limitParams, paginationParams, setLoading, tokens) => {
  setLoading(true);
  const result = await axios.get(`${url}/user`, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY,
      'Authorization': tokens,
    },
    params: {
      name: nameParams,
      totalPerPage: limitParams,
      pageNo: paginationParams,
    },
  }).catch((error) => error);
  return result;
};

// CreateWorker
export const APICreateWorker = async (params, setLoading, tokens) => {
  setLoading(true);
  const result = await axios.post(`${url}/worker`, params, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY,
      'Authorization': tokens,
    },
  }).catch((error) => error);
  return result;
};

export const APICreateUser = async (params, setLoading, tokens) => {
  setLoading(true);
  const result = await axios.post(`${url}/user`, params, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY,
      'Authorization': tokens,
    },
  }).catch((error) => error);
  return result;
};

// <Deprecated>
// export const APICreateCard = async (params, workerId, setLoading, tokens) => {
//   setLoading(true);
//   const result = await axios.post(`${url}/card/${workerId}`, params, {
//     headers: {
//       'x-api-key': process.env.REACT_APP_X_API_KEY,
//       'Authorization': tokens,
//     },
//   }).catch((error) => error);
//   return result;
// };

// <Deprecated>
export const APIGenerateCardId = async (params, workerId, setLoading, tokens) => {
  setLoading(true);
  const result = await axios.post(`${url}/cardid/${workerId}`, params, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY,
      'Authorization': tokens,
    },
  }).catch((error) => error);
  return result;
};

export const APICreateWorkerCard = async (workerId, params, setLoading, tokens) => {
  setLoading(true);
  const result = await axios.post(`${url}/card/${workerId}`, params, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY,
      'Authorization': tokens,
    },
  }).catch((error) => error);
  return result;
};

export const APIUpdateWorker = async (params, workerId, setLoading, tokens) => {
  setLoading(true);
  const result = await axios.patch(`${url}/worker/${workerId}`, params, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY,
      'Authorization': tokens,
    },
  }).catch((error) => error);
  return result;
};

export const APIBulkUpdateWorker = async (params, setLoading, tokens) => {
  setLoading(true);
  const result = await axios.patch(`${url}/bulk/worker`, params, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY,
      'Authorization': tokens,
    },
  }).catch((error) => error);
  return result;
};

export const APICardReadinessToPrint = async (params, setLoading, tokens) => {
  setLoading(true);
  const result = await axios.get(`${url}/print`, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY,
      'Authorization': tokens,
    },
    params: {
      workerId: params,
    },
  }).catch((error) => error);
  return result;
};
export const APIUpdateWorkerCard = async (params, workerId, setLoading, tokens) => {
  setLoading(true);
  const result = await axios.patch(`${url}/card/${workerId}`, params, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY,
      'Authorization': tokens,
    },
  }).catch((error) => error);
  return result;
};

export const APIBulkUpdateWorkerCard = async (params, setLoading, tokens) => {
  setLoading(true);
  const result = await axios.patch(`${url}/bulk/card`, params, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY,
      'Authorization': tokens,
    },
  }).catch((error) => error);
  return result;
};

export const APIUpdateUser = async (params, userId, setLoading, tokens) => {
  setLoading(true);
  const result = await axios.patch(`${url}/user/${userId}`, params, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY,
      'Authorization': tokens,
    },
  }).catch((error) => error);
  return result;
};

export const APIArchivedWorker = async (workerId, setLoading, tokens) => {
  setLoading(true);
  const result = await axios.delete(`${url}/worker/${workerId}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY,
      'Authorization': tokens,
    },
  }).catch((error) => error);
  return result;
};

export const APIDeleteUser = async (userId, setLoading, tokens) => {
  setLoading(true);
  const result = await axios.delete(`${url}/user/${userId}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY,
      'Authorization': tokens,
    },
  }).catch((error) => error);
  return result;
};

export const APIFetchCompany = async (params, nameFilter, limitParams, paginationParams, setLoading, tokens) => {
  setLoading(true);
  const result = await axios.get(`${url}/company`, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY,
      'Authorization': tokens,
    },
    params: {
      name: nameFilter && nameFilter,
      industry: params && params.industry,
      pageNo: paginationParams,
      totalPerPage: limitParams,
    },
  }).catch((error) => error);
  return result;
};

// CreateWorker
export const APICreateCompany = async (params, setLoading, tokens) => {
  setLoading(true);
  const result = await axios.post(`${url}/company`, params, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY,
      'Authorization': tokens,
    },
  }).catch((error) => error);
  return result;
};

export const APIUpdateCompany = async (params, companyId, setLoading, tokens) => {
  setLoading(true);
  const result = await axios.patch(`${url}/company/${companyId}`, params, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY,
      'Authorization': tokens,
    },
  }).catch((error) => error);
  return result;
};

export const APIDeleteCompany = async (companyId, setLoading, tokens) => {
  setLoading(true);
  const result = await axios.delete(`${url}/company/${companyId}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_X_API_KEY,
      'Authorization': tokens,
    },
  }).catch((error) => error);
  return result;
};
